import { Dispatch, MutableRefObject, SetStateAction, useEffect, useMemo, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useRouter } from 'next/router';
import { useSWRConfig } from 'swr';
import { ValidationError } from 'yup';
import {
    Button,
    Card,
    CardHeader,
    HTMLEmailTemplateTextEditor,
    InputFormGroup,
    Tab,
    Tabs,
    ToggleSwitch,
    InfoTooltip,
    Label
} from '@components';
import { CheckSecurityModal, CustomizationVariablesModal } from '@containers';
import { ConfirmCancellationDialog } from '@dialogs';
import { VariableParentKey, useFetch, useTemplate, useTranslator } from '@hooks';
import {
    CustomizationVariable,
    CustomizationVariableGroupName,
    EmailTemplateData,
    MessageSequenceStep,
    SendingTechnologyTypeEnum,
    SubToolIdEnum,
    Theme,
    themeTypeBySubToolId
} from '@models';
import { useAlert, useAuth } from '@providers';
import { captureException } from '@sentry/nextjs';
import { FormHandles, useField } from '@unform/core';
import {
    CUSTOM_VARIABLES_GUIDE_LINK,
    isDevelopment,
    isGreaterThanZero,
    isInvisible,
    LOCAL_STORAGE_TEMP_PRODUCTS_KEY,
    MAX_SUBJECT_SIZE,
    scrollTo
} from '@utils';
import {
    GetProductsForTemplateSubmissionTestingAsync,
    GetProductsForTemplateSubmissionTestingURL
} from '~/services/apiService';
import { GetThemeTemplateCacheKey } from '~/services/cacheKeys';
import { GetThemeTemplateAsync, NEWSLETTER_THEME_DETAILS_URL } from '~/services/newsletterApiService';
import { TestEmailRequestPayload } from '~/services/subToolApiService';
import { HandleSendTestEmail } from '~/services/subToolService';
import {
    normalizeBaseThemeVariables,
    removeBaseThemeVariables,
    replaceParagraphTagWithDiv,
    translatedStepTemplates
} from '~/services/templateService';
import { theme } from '~/styles/theme';
import { Themes } from '../../themes';
import { EmailTemplatePreview } from '../emailTemplatePreview';
import {
    CardBodyStyled,
    ContentStyled,
    InputGroupStyled,
    TabContentButtonStyled,
    TabContentLabelStyled,
    TabContentStyled,
    TitleStyled,
    ToolTipChildrenStyled,
    VariableInfoContainer
} from './styles';
import { useTesteEmailValidation } from './validation';

type TabsNames = 'baseHTML' | 'productHTML' | 'reserveProductHTML' | 'recommendationHTML' | 'reviewHTML';

type EditorData = {
    htmlContent?: string;
    setHtmlContent: Dispatch<SetStateAction<string>>;
    name: TabsNames;
};

type CreateOrUpdateEmailTemplateProps = {
    editorFormGroupIdentifier: string;
    customizationVariables?: Array<CustomizationVariable>;
    initialData: EmailTemplateData;
    formRef: MutableRefObject<FormHandles>;
    subToolKeyIdentifier: VariableParentKey;
    baseThemeId?: number;
    onDataChange?: () => void;
    onChangeEnableTechnology?: (checked: boolean) => void;
    enableTechnology?: boolean;
    showTechnologyEnableToggleSwitch?: boolean;
    subToolColor?: string;
    showTabs?: Array<TabsNames>;
    subToolId: SubToolIdEnum;
};

export const CreateOrUpdateEmailTemplate = ({
    editorFormGroupIdentifier,
    customizationVariables = [],
    initialData,
    formRef,
    subToolKeyIdentifier,
    baseThemeId,
    onDataChange,
    onChangeEnableTechnology,
    enableTechnology,
    subToolColor,
    subToolId,
    showTechnologyEnableToggleSwitch = true,
    showTabs = [
        'baseHTML',
        subToolId !== SubToolIdEnum.CustomTrigger ? 'productHTML' : null,
        subToolId !== SubToolIdEnum.CustomTrigger ? 'reviewHTML' : null,
        subToolId === SubToolIdEnum.CartRecovery
            ? 'recommendationHTML'
            : subToolId !== SubToolIdEnum.BankSlipReminder && subToolId !== SubToolIdEnum.CustomTrigger
            ? 'reserveProductHTML'
            : null
    ].filter(Boolean) as Array<TabsNames>
}: CreateOrUpdateEmailTemplateProps) => {
    const [isCheckSecurityModalOpen, setIsCheckSecurityModalOpen] = useState(false);
    const { locale, locales, defaultLocale } = useRouter();

    const [subject, setSubject] = useState(initialData?.subject ?? '');
    const [baseHTML, setBaseHTML] = useState(initialData?.baseHTML ?? '');
    const [productHTML, setProductHTML] = useState(initialData?.productHTML ?? '');
    const [reserveProductHTML, setReserveProductHTML] = useState(initialData?.reserveProductHTML ?? '');
    const [recommendationHTML, setRecommendationHTML] = useState(initialData?.recommendationHTML ?? '');
    const [reviewHTML, setReviewHTML] = useState(initialData?.reviewHTML ?? '');

    const [currentHTMLError, setCurrentHTMLError] = useState(null);
    const [currentTabIndex, setCurrentTabIndex] = useState(0);
    const [erroredFieldName, setErroredFieldName] = useState(null);
    const [selectedThemeId, setSelectedThemeId] = useState<number>(baseThemeId);
    const [showTemplateTab, setShowTemplateTab] = useState(!isEmpty(initialData?.baseHTML));
    const [displayReserveProduct, setDisplayReserveProduct] = useState(false);
    const { show, error, success } = useAlert();
    const { registerField } = useField('baseThemeId');

    const translator = useTranslator();
    const { title, label, inputs, variables } = translator.subTools.emailModelData;
    const { errors, successes, questions } = translator.dialogs;

    const { testeEmailSchema } = useTesteEmailValidation(editorFormGroupIdentifier);
    const { generateTemplatePreviewBySendingTechnology, generateEmailSubjectPreview } =
        useTemplate(subToolKeyIdentifier);
    const { user } = useAuth();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const formData = {
        subject: null,
        baseHTML,
        productHTML,
        reserveProductHTML,
        recommendationHTML,
        reviewHTML
    };

    let editorData: Array<EditorData> = [
        { htmlContent: baseHTML, setHtmlContent: setBaseHTML, name: 'baseHTML' },
        { htmlContent: productHTML, setHtmlContent: setProductHTML, name: 'productHTML' },
        { htmlContent: reserveProductHTML, setHtmlContent: setReserveProductHTML, name: 'reserveProductHTML' },
        { htmlContent: recommendationHTML, setHtmlContent: setRecommendationHTML, name: 'recommendationHTML' },
        { htmlContent: reviewHTML, setHtmlContent: setReviewHTML, name: 'reviewHTML' }
    ];

    editorData = editorData.filter((data) => showTabs.includes(data.name));

    let tabsLabels = [
        { id: null, name: String(label.viewTheme) },
        { id: 'baseHTML', name: String(label.editHTMLCode) },
        { id: 'productHTML', name: String(label.editProducts) },
        { id: 'reserveProductHTML', name: String(label.editReserveProducts) },
        { id: 'recommendationHTML', name: String(label.editRecomendations) },
        { id: 'reviewHTML', name: String(label.editReviews) }
    ];

    tabsLabels = tabsLabels.filter((data) => !data.id || showTabs.includes(data.id as TabsNames));

    const { data: baseTheme } = useFetch(
        selectedThemeId &&
            ({
                url: NEWSLETTER_THEME_DETAILS_URL,
                themeId: selectedThemeId,
                themeType: themeTypeBySubToolId[subToolId]
            } as GetThemeTemplateCacheKey),
        async ({ themeId, themeType }) => {
            const response = GetThemeTemplateAsync(themeId, themeType);

            return (await response).data;
        }
    );

    const openCheckSecurityModal = async () => {
        try {
            formRef.current.setFieldError(`${editorFormGroupIdentifier}.subject`, null);
            formRef.current.setFieldError(`${editorFormGroupIdentifier}.${editorData[0].name}`, null);
            formRef.current.setFieldError(`${editorFormGroupIdentifier}.testEmail`, null);

            const testEmail = formRef.current.getFieldValue(`${editorFormGroupIdentifier}.testEmail`);

            await testeEmailSchema.validate(
                { [editorFormGroupIdentifier]: { subject, baseHTML, testEmail } },
                { abortEarly: false }
            );

            setIsCheckSecurityModalOpen(true);
        } catch (exception) {
            if (exception instanceof ValidationError) {
                const errorMessages = {};
                exception.inner.forEach((error) => (errorMessages[error.path] = error.message));

                const currentErrors = formRef.current.getErrors();
                formRef.current.setErrors({ ...currentErrors, ...errorMessages });

                const firstErroredFieldName = Object.keys(currentErrors)[0];
                setErroredFieldName(firstErroredFieldName);

                isDevelopment() && console.log(errorMessages);
            } else {
                error(String(errors.failedToSendTestEmail.validationError), (exception as Error)?.message);
                captureException(exception);
            }
        }
    };

    const variablesList = useMemo(() => {
        let variables: Array<string> = [];
        customizationVariables.forEach((item) => {
            variables = [...variables, ...item.variables.map((variableName) => variableName?.toLowerCase())];
        });
        return variables.filter(Boolean);
    }, [customizationVariables]);

    const subjectVariablesList = useMemo(() => {
        return customizationVariables
            ?.find((item) => item.name === CustomizationVariableGroupName.subjectData)
            ?.variables.map((variableName) => variableName?.toLowerCase())
            ?.filter(Boolean);
    }, [customizationVariables]);

    const { mutate } = useSWRConfig();

    const closeCheckSecurityModal = async (recaptcha?: string) => {
        setIsCheckSecurityModalOpen(false);

        try {
            if (recaptcha) {
                const hasProducts = !!localStorage.getItem(LOCAL_STORAGE_TEMP_PRODUCTS_KEY);

                if (!hasProducts) {
                    const MAX_PRODUCTS = 10;
                    await GetProductsForTemplateSubmissionTestingAsync(MAX_PRODUCTS).then((response) => {
                        if (isGreaterThanZero(response.data.length)) {
                            localStorage.setItem(LOCAL_STORAGE_TEMP_PRODUCTS_KEY, JSON.stringify(response.data));
                            mutate({ url: GetProductsForTemplateSubmissionTestingURL, user }, response, false);
                        }
                    });
                }

                const content = generateTemplatePreviewBySendingTechnology({
                    baseHtml: formData.baseHTML,
                    productHtml: formData?.productHTML,
                    reviewHtml: formData?.reviewHTML,
                    recommendationHTML: formData?.recommendationHTML,
                    user,
                    allowedVariables: variablesList,
                    sendingTechnology: SendingTechnologyTypeEnum.Email,
                    displayReserveProduct,
                    reserveProductHtml: formData?.reserveProductHTML
                });

                const testEmail = formRef.current.getFieldValue(`${editorFormGroupIdentifier}.testEmail`);
                const payload: TestEmailRequestPayload = {
                    subToolId,
                    to: { email: testEmail },
                    subject: generateEmailSubjectPreview(subject, user, subjectVariablesList),
                    template: content,
                    recaptchaToken: recaptcha
                };

                await HandleSendTestEmail(payload)
                    .then((response) => {
                        if (!response?.data?.success) {
                            error(
                                String(errors.failedToSendTestEmail.title),
                                String(errors.failedToSendTestEmail.description)
                            );
                        } else {
                            success(String(successes.successfullySendingTestEmail));
                        }
                    })
                    .catch((exception) => {
                        error(
                            String(errors.failedToSendTestEmail.title),
                            String(errors.failedToSendTestEmail.description)
                        );
                        captureException(exception);
                    });
            }
        } catch (exception) {
            captureException(exception);
            error(String(errors.failedToSendTestEmail.title), String(errors.failedToSendTestEmail.description));
        }
    };

    const openCustomizationVariablesModal = () => {
        show(null, null, {
            content: (data) => (
                <CustomizationVariablesModal
                    {...data}
                    customizationVariables={customizationVariables}
                    subToolKeyIdentifier={subToolKeyIdentifier}
                />
            )
        });
    };

    const handleChange = (newData: string, saveDataToContextCallback: (newData: string) => void) => {
        const newHtml = replaceParagraphTagWithDiv(newData, subToolId, { locale, locales, defaultLocale });
        saveDataToContextCallback(newHtml);

        if (onDataChange) {
            onDataChange();
        }

        return newHtml;
    };

    const handleError = async (tabIndex: number) => {
        const timeoutToSyncScroll = 400;

        // NOTE: Use timeout to wait for page scrolling to run before scrolling tabs
        setTimeout(() => {
            setCurrentTabIndex(tabIndex + 1);
        }, timeoutToSyncScroll);
    };

    useEffect(() => {
        if (erroredFieldName) {
            let erroredField = formRef.current.getFieldRef(erroredFieldName);
            if (isInvisible(erroredField)) {
                erroredField = document.getElementsByName(erroredField.getAttribute('name'))[0];
            }

            const baseHtmlField = `${editorFormGroupIdentifier}.${editorData[0].name}`;
            if (erroredFieldName === baseHtmlField) {
                erroredField = document.getElementById(baseHtmlField)?.parentElement;
            }

            erroredField?.focus && erroredField.focus();

            scrollTo(erroredField);
            setErroredFieldName(null);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [erroredFieldName, formRef, editorFormGroupIdentifier]);

    const handleCloseTemplateTabs = () => {
        show(null, null, {
            content: ({ options }) => (
                <ConfirmCancellationDialog
                    options={options}
                    title={String(questions.themeChange)}
                    onToBackClick={() => options.onOpenChange(false)}
                    onYesClick={() => {
                        setBaseHTML(initialData?.baseHTML ?? '');
                        setProductHTML(initialData?.productHTML ?? '');
                        setReserveProductHTML(initialData?.reserveProductHTML ?? '');
                        setRecommendationHTML(initialData?.recommendationHTML ?? '');
                        setReviewHTML(initialData?.reviewHTML ?? '');
                        setSelectedThemeId(null);

                        // this timeout is used to allow time to clear the template values before closing the tabs
                        const waitTimeToUpdateState = 200;
                        setTimeout(() => {
                            setShowTemplateTab(false);
                            setCurrentTabIndex(0);
                        }, waitTimeToUpdateState);
                        options.onOpenChange(false);
                    }}
                />
            )
        });
    };

    const handleSelectedTheme = (selectedTheme?: Theme) => {
        formRef.current.setFieldError(`${editorFormGroupIdentifier}.${editorData[0].name}`, null);
        if (!selectedTheme) {
            setShowTemplateTab(true);
            setSelectedThemeId(null);
        } else {
            setSelectedThemeId(selectedTheme?.id);
        }
    };

    useEffect(() => {
        registerField<number>({
            name: `baseThemeId`,
            getValue() {
                return selectedThemeId;
            }
        });
    }, [registerField, selectedThemeId]);

    useEffect(() => {
        if (baseTheme) {
            setShowTemplateTab(true);
            const defaultTheme = removeBaseThemeVariables(baseTheme);
            const { baseHTML, productHtml, reviewHtml } = normalizeBaseThemeVariables({
                baseHTML: defaultTheme.htmlBase,
                productHtml: defaultTheme.htmlItems,
                reviewHtml: defaultTheme.htmlReviews
            });

            let templateStep = {
                templates: [
                    {
                        baseContent: baseHTML,
                        productContent: productHtml,
                        reviewContent: reviewHtml
                    }
                ]
            } as MessageSequenceStep;

            templateStep = translatedStepTemplates({ locale, locales, defaultLocale }, templateStep, subToolId);
            const emailTemplate = templateStep.templates[0];

            setBaseHTML(emailTemplate.baseContent);
            setProductHTML(emailTemplate?.productContent);
            setReviewHTML(emailTemplate?.reviewContent);
        }
    }, [baseTheme, subToolId, locale, locales, defaultLocale]);

    const showReserveProduct = showTabs.includes('reserveProductHTML') ? displayReserveProduct : null;

    return (
        <>
            <CheckSecurityModal isOpen={isCheckSecurityModalOpen} onClose={closeCheckSecurityModal} />
            <Card type='group' borderColor={subToolColor}>
                <CardHeader
                    title={
                        <TitleStyled>
                            {showTechnologyEnableToggleSwitch && (
                                <ToggleSwitch
                                    name={`${editorFormGroupIdentifier}Enabled`}
                                    onChange={(event) => onChangeEnableTechnology(event.target.checked)}
                                    enableUnform
                                    checked={enableTechnology}
                                    color={theme.colors.purple}
                                />
                            )}
                            {title}
                        </TitleStyled>
                    }
                    actions={
                        customizationVariables && enableTechnology ? (
                            <VariableInfoContainer>
                                <Button onClick={openCustomizationVariablesModal} color='purple' isOutlined>
                                    {variables.label}
                                    <InfoTooltip>
                                        <ToolTipChildrenStyled onClick={(event) => event.stopPropagation()}>
                                            {variables.helpButton.title}
                                            <a
                                                target='_blank'
                                                rel='noopener noreferrer'
                                                href={CUSTOM_VARIABLES_GUIDE_LINK}>
                                                {variables.helpButton.clickHere}
                                            </a>
                                        </ToolTipChildrenStyled>
                                    </InfoTooltip>
                                </Button>
                            </VariableInfoContainer>
                        ) : null
                    }
                />
                <CardBodyStyled cardChild isExpanded={enableTechnology}>
                    <ContentStyled
                        isExpanded={enableTechnology}
                        initial={{ height: enableTechnology === false ? 0 : 'auto' }}>
                        <div className='subject-group'>
                            <InputFormGroup
                                type='text'
                                autoComplete='off'
                                inputSize='small'
                                label={String(label.subject)}
                                placeholder={String(inputs.subject.placeholder)}
                                name={`${editorFormGroupIdentifier}.subject`}
                                testId={`${editorFormGroupIdentifier}Subject`}
                                onChange={(event) => setSubject(event.target.value)}
                            />
                            <span>
                                {subject?.length ?? 0}/{MAX_SUBJECT_SIZE}
                            </span>
                        </div>
                        {!showTemplateTab ? (
                            <Themes
                                onSelectedTheme={handleSelectedTheme}
                                variablesList={variablesList}
                                subToolId={subToolId}
                            />
                        ) : (
                            <TabContentStyled>
                                <TabContentLabelStyled>
                                    <Label>{String(label.personalization)}</Label>
                                    <Label as='span' size='sm' onClick={handleCloseTemplateTabs}>
                                        {String(translator.subTools.newsletterTheme.actions.changeTheme)}
                                    </Label>
                                </TabContentLabelStyled>
                                <Tabs
                                    labels={tabsLabels.map((tab) => tab.name)}
                                    defaultTab={currentTabIndex}
                                    onTabChange={setCurrentTabIndex}
                                    scrollTab>
                                    <Tab>
                                        {/* TODO: This functionality will be done in another US */}
                                        {/* <Button buttonSize='small' color='default' onClick={() => null}>
                                            {buttons.duplicateTheme}
                                        </Button> */}
                                        <EmailTemplatePreview
                                            subject={subject}
                                            baseHtml={baseHTML}
                                            productHtml={productHTML}
                                            reviewHtml={reviewHTML}
                                            variables={variablesList}
                                            subToolId={subToolId}
                                            reserveProductHtml={reserveProductHTML}
                                            recommendationHTML={recommendationHTML}
                                            displayReserveProduct={showReserveProduct}
                                            onChangeDisplayReserveProduct={() =>
                                                setDisplayReserveProduct(!displayReserveProduct)
                                            }
                                        />
                                    </Tab>
                                    {editorData.map((editorDatum, index) => (
                                        <Tab key={`${editorFormGroupIdentifier}${index}`}>
                                            <TabContentButtonStyled>
                                                {/* TODO: This functionality will be done in another US */}
                                                {/* <Button color='default' buttonSize='small' onClick={() => null}>
                                                    {buttons.pasteHtml}
                                                </Button> */}
                                                {/* <Button color='default' buttonSize='small' onClick={() => null}>
                                                    {buttons.duplicateTheme}
                                                </Button> */}
                                            </TabContentButtonStyled>
                                            <HTMLEmailTemplateTextEditor
                                                formGroupIdentifier={editorFormGroupIdentifier}
                                                uniqueName={`${editorFormGroupIdentifier}.${editorDatum.name}`}
                                                initialData={editorDatum.htmlContent}
                                                onDataChange={(newData) =>
                                                    handleChange(newData, editorDatum.setHtmlContent)
                                                }
                                                formData={formData}
                                                currentHTMLError={currentHTMLError}
                                                setCurrentHTMLError={setCurrentHTMLError}
                                                onError={() => handleError(index)}
                                            />
                                        </Tab>
                                    ))}
                                </Tabs>
                            </TabContentStyled>
                        )}
                        <InputGroupStyled
                            inputType='email'
                            inputName={`${editorFormGroupIdentifier}.testEmail`}
                            buttonText={String(inputs.email.button)}
                            inputPlaceHolder={String(inputs.email.placeholder)}
                            onClick={openCheckSecurityModal}
                        />
                    </ContentStyled>
                </CardBodyStyled>
            </Card>
        </>
    );
};
