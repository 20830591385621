import HTMLReactParser from 'html-react-parser';
import { format } from '~/utils/whatsappFormat';
import {
    ContainerStyled,
    ConversationStyled,
    MessageHeaderStyled,
    MessageBodyStyled,
    MessageFooterStyled
} from './styles';

type WhatsAppTemplatePreviewProps = {
    header?: string;
    message?: string;
    footer?: string;
};

export const WhatsAppTemplatePreview = ({ header, message, footer }: WhatsAppTemplatePreviewProps) => {
    return (
        <ContainerStyled>
            <ConversationStyled>
                <div>
                    {header && <MessageHeaderStyled>{HTMLReactParser(format(header))}</MessageHeaderStyled>}
                    <MessageBodyStyled>{HTMLReactParser(format(message))}</MessageBodyStyled>
                    {footer && <MessageFooterStyled>{HTMLReactParser(format(footer))}</MessageFooterStyled>}
                </div>
            </ConversationStyled>
        </ContainerStyled>
    );
};
