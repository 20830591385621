import styled from 'styled-components';
import { Form } from '@unform/web';
import { Button } from '~/components/elements/button';

export const FormStyled = styled(Form)`
    display: flex;
    flex-direction: column;
    gap: 2.4rem;

    & > button {
        margin-top: 3.2rem;
        margin-left: auto;
        width: 16rem;
    }
`;

export const FormContentStyled = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
`;

export const FacebookButton = styled(Button)`
    background-color: ${({ theme }) => theme.colors.royalBlue};
    border-color: ${({ theme }) => theme.colors.royalBlue};
    display: inline-flex;
    gap: 0.8rem;
    height: 4rem;
    max-width: fit-content;
    min-width: 22.5rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    img,
    svg {
        width: 2.5rem;
        height: 2.5rem;
    }

    & > div {
        width: 2.5rem;
        height: 2.5rem;
        overflow: hidden;
    }

    .facebook-button__text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: start;
        flex: 1;
        margin-top: 0.25rem;
    }
`;
