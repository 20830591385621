import {
    FacebookUserData,
    WhatsAppPhoneNumber,
    WhatsAppConfiguration,
    WhatsAppBusinessAccount,
    WhatsAppBusinessMessageTemplate
} from '@models';
import axiosClient from './apiService';

const route = '/sending-technology/whatsapp';
export const getConfigurationRoute = `${route}/configuration`;
export const getUserInfoRoute = `${route}/user-info`;
export const getAccountsRoute = `${route}/accounts`;
export const getPhoneNumbersUrl = (accountId: string) => `${getAccountsRoute}/${accountId}/phone-numbers`;
export const listMessageTemplatesRoute = `${route}/message-templates`;

export const GetWhatsAppConfigurationAsync = async (signal: AbortSignal = null) => {
    return axiosClient.get<WhatsAppConfiguration>(getConfigurationRoute, { signal });
};

export const SaveWhatsAppConfigurationAsync = async (
    whatsAppBusinessAccountId?: string,
    phoneNumberId?: string,
    signal: AbortSignal = null
) => {
    return axiosClient.put<WhatsAppConfiguration>(
        getConfigurationRoute,
        { whatsAppBusinessAccountId, phoneNumberId },
        { signal }
    );
};

export const GetFacebookUserInfoAsync = async (signal: AbortSignal = null) => {
    return axiosClient.get<FacebookUserData>(getUserInfoRoute, { signal });
};

export const GetWhatsAppBusinessAccountsAsync = async (signal: AbortSignal = null) => {
    return axiosClient.get<WhatsAppBusinessAccount[]>(getAccountsRoute, { signal });
};

export const GetPhoneNumbersAsync = async (accountId: string, signal: AbortSignal = null) => {
    return axiosClient.get<WhatsAppPhoneNumber[]>(getPhoneNumbersUrl(accountId), { signal });
};

export type WhatsAppBusinessMessageTemplatesFilters = {
    category?: string;
    content?: string;
    language?: string;
    name?: string;
    nameOrContent?: string;
    qualityScore?: string;
    status?: string;
};
export const ListMessageTemplatesAsync = async (
    params: WhatsAppBusinessMessageTemplatesFilters,
    signal: AbortSignal = null
) => {
    return axiosClient.get<WhatsAppBusinessMessageTemplate[]>(listMessageTemplatesRoute, { params, signal });
};

export type WhatsAppBusinessMessageTemplateTestPayload = {
    to: string;
    templateId: string;
    subToolId: number;
    tagAnalytics: Record<string, string>;
};
export const SendTestMessage = async (
    payload: WhatsAppBusinessMessageTemplateTestPayload,
    signal: AbortSignal = null
) => {
    return axiosClient.post(`${route}/send-test-message`, payload, { signal });
};
