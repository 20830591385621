export enum WhatsAppConfigurationStatus {
    Inactive = 0,
    Active = 1,
    Configuring = 2
}

export type WhatsAppConfiguration = {
    status: WhatsAppConfigurationStatus;
    facebookManagerId?: string;
    whatsAppBusinessPhone?: string;
};

export type FacebookUserData = {
    id: string;
    name: string;
    email: string;
    picture?: string;
};

export type WhatsAppBusinessAccount = {
    id: string;
    name: string;
    verificationStatus?: string;
    messageTemplateNamespace?: string;
};

export type WhatsAppPhoneNumber = {
    id: string;
    displayPhoneNumber: string;
    verifiedName: string;
    status: string;
    codeVerificationStatus: string;
    qualityRating: string;
    platformType: string;
    isOfficialBusinessAccount?: boolean;
    throughputLevel: string;
};

type WhatsAppBusinessMessageTemplateComponentNamedParam = {
    name: string;
    example: string;
};

export type WhatsAppBusinessMessageTemplateComponent = {
    template: string;
    format?: string;
    params?: string[];
    namedParams?: WhatsAppBusinessMessageTemplateComponentNamedParam[];
};

export type WhatsAppBusinessMessageTemplateButton = {
    type: string;
    text: string;
    url?: string;
    phoneNumber?: string;
    example?: string[];
};

export type WhatsAppBusinessMessageTemplate = {
    id: string;
    name: string;
    language: string;
    status: string;
    category: string;
    subCategory?: string;
    parameterFormat: string;
    header?: WhatsAppBusinessMessageTemplateComponent;
    body: WhatsAppBusinessMessageTemplateComponent;
    footer?: WhatsAppBusinessMessageTemplateComponent;
    buttons?: WhatsAppBusinessMessageTemplateButton[];
};
