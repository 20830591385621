import * as Yup from 'yup';
import { MAX_LENGTH_TELEPHONE, MIN_LENGTH_TELEPHONE } from '@utils';
import { useTranslator } from '~/hooks/useTranslator';

export const useValidation = (inputName: string, testInputName: string) => {
    const {
        subTools: {
            modelsTemplateEditor: { errorMessages }
        }
    } = useTranslator();

    const schema = Yup.object().shape({
        [inputName]: Yup.string().required(errorMessages.emptySms).nullable(),

        [testInputName]: Yup.string()
            .required(errorMessages.senderCellPhone.required)
            .nullable()
            .min(MIN_LENGTH_TELEPHONE, errorMessages.senderCellPhone.minLength)
            .max(MAX_LENGTH_TELEPHONE, errorMessages.senderCellPhone.maxLength)
    });

    const validate = async (message: string, cellPhoneInputValue: string) => {
        return schema.validate({ [inputName]: message, [testInputName]: cellPhoneInputValue }, { abortEarly: false });
    };

    return {
        schema,
        validate
    };
};
