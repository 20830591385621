import axios, { AxiosError, AxiosResponse, CanceledError } from 'axios';
import { IncomingMessage } from 'http';
import _isNil from 'lodash/isNil';
import Router from 'next/router';
import { parseCookies } from 'nookies';
import {
    AddressCountry,
    BillingPeriod,
    CardFlag,
    CouponAttemptResult,
    CustomizationVariable,
    PaymentMethod,
    Product,
    SendingTechnologyTypeEnum,
    Subscription,
    SubscriptionInvoice,
    SubTool,
    SubToolId,
    Tool,
    ToolTypeEnum,
    User
} from '@models';
import { captureException } from '@sentry/nextjs';
import { HttpStatusCodeEnum, isBrowser, PAINELN2_STATUS, parseResponse, toBase64, translateRoute } from '@utils';
import { SignOut } from './authService';
import { EnviouApiResponseError } from './errors/enviouApiResponseError';
import { EnviouApiUnprocessableEntityError } from './errors/enviouApiUnprocessableEntityError';
import { SystemErrorStatus } from './errors/statusError';

export type SignInResponseData = {
    user: User;
    token: string;
    expiresIn: Date;
    antiForgeryToken?: string;
    needs2FA?: boolean;
};

export type BackEndResponseErrorData<T = unknown, U = unknown> = {
    code: T;
    data?: U;
};

export type BackEndResponseError<T = SystemErrorStatus.InternalError, U = unknown> = {
    message?: string;
    code?: string;
    errors?: Array<BackEndResponseErrorData<T, U>>;
};

export type UnprocessableEntityError = {
    field?: string;
    message: string;
};

export type BackEndUnprocessableEntityResponseError = {
    message: string;
    errors: Array<UnprocessableEntityError>;
};

export type ResponseError = AxiosResponse<BackEndResponseError>;

const TWO_FACTOR_ERROS_CODE = [
    SystemErrorStatus[SystemErrorStatus.TwoFactorCodeInvalid],
    SystemErrorStatus[SystemErrorStatus.TwoFactorResendTokenError]
];

// #region Axios Config
const tokenCookieName = process.env.NEXT_PUBLIC_TOKEN_COOKIE_NAME;

export const axiosClient = axios.create({
    baseURL: process.env.NEXT_PUBLIC_ENVIOU_API,
    transformResponse: (data) => data && parseResponse(data)
});

const hasTwoFactorError = (response?: AxiosResponse<BackEndResponseError<SystemErrorStatus>>) => {
    return (
        response &&
        response.data &&
        response.data.errors &&
        response.data.errors.length > 0 &&
        response.data.errors.find((erro) => TWO_FACTOR_ERROS_CODE.includes(String(erro.code)))
    );
};

axiosClient.interceptors.response.use(
    (response) => response,
    (error: AxiosError) => {
        try {
            const twoFactorCodeInvalid = hasTwoFactorError(error.response);
            if (error?.response?.status === Number(HttpStatusCodeEnum.Unauthorized) && !twoFactorCodeInvalid) {
                SignOut();

                if (Router.route !== '/signIn') {
                    Router.push(translateRoute('/signIn', Router.locale));
                }
            }

            if (error instanceof CanceledError) {
                return Promise.reject(error);
            }

            if (error.response) {
                const { data, config, status } = error.response;
                const url = `${config?.baseURL}${config?.url}`;

                if (status === Number(HttpStatusCodeEnum.UnprocessableEntity)) {
                    const backEndUnprocessableEntityResponseError = data as BackEndUnprocessableEntityResponseError;

                    return Promise.reject(
                        new EnviouApiUnprocessableEntityError(
                            backEndUnprocessableEntityResponseError.message,
                            backEndUnprocessableEntityResponseError.errors
                        )
                    );
                }
                const backEndResponseError = data as BackEndResponseError;

                return Promise.reject(
                    new EnviouApiResponseError(
                        backEndResponseError?.message ?? error.message ?? 'Unexpected error',
                        backEndResponseError?.code ?? error.code,
                        backEndResponseError?.errors,
                        url,
                        status,
                        Router
                    )
                );
            }

            return Promise.reject(new EnviouApiResponseError(`Empty response, likely due to a server error: ${error}`));
        } catch (exception) {
            captureException(exception);
        }
    }
);

axiosClient.interceptors.request.use(
    async (request) => {
        if (request.headers.Authorization === undefined) {
            const { [tokenCookieName]: tokenCookieValue } = parseCookies({ req: request as IncomingMessage });

            if (tokenCookieValue) {
                request.headers.Authorization = `Bearer ${JSON.parse(tokenCookieValue)}`;
            }
        }

        if (isBrowser()) {
            request.headers['Accept-Language'] = Router.locale;
        }

        return request;
    },
    (error) => Promise.reject(error)
);

export const SetDefaultHeader = (props: { [key: string]: string }) => {
    Object.entries(props).forEach(([key, value]) => {
        axiosClient.defaults.headers[key] = value;
    });
};

// #endregion

// #region Account

export const SignInRequest = async (username: string, password: string) => {
    return axiosClient.get<SignInResponseData>('account/sign-in', {
        headers: { Authorization: 'Basic ' + toBase64(`${username}:${password}`) }
    });
};

export const TwoFactorAuthenticationRequest = async (antiForgeryToken: string, twoFactorToken: string) => {
    return axiosClient.post<SignInResponseData>('account/2fa', {
        antiForgeryToken,
        twoFactorToken
    });
};

export const ResendTwoFactorToken = async (antiForgeryToken: string) => {
    return axiosClient.post<SignInResponseData>('account/2fa/resend', {
        antiForgeryToken
    });
};

export const GetAuthenticatedUserRequest = async () => {
    return axiosClient.get<User>('account/user');
};

export const AuthenticateByShortLivedToken = async (shortLivedToken: string) => {
    return axiosClient.get<SignInResponseData>(`account/slt/${shortLivedToken}`);
};

export const RecoverPasswordRequest = async (username: string) => {
    return axiosClient.get<boolean>('account/recover-password', { params: { username } });
};

// #endregion

// #region Tools
export const GetTools = async () => {
    return axiosClient.get<Array<Tool>>('tool');
};

export const GetToolById = async (id: number) => {
    return axiosClient.get<Tool>(`tool/${id}`);
};

export const GetSubTools = async (toolType?: ToolTypeEnum) => {
    const parsedParams = _isNil(toolType) ? {} : { toolType };

    return axiosClient.get<Array<SubTool>>('tool/sub-tools', { params: parsedParams });
};

export const GetSubToolByIdURL = 'tool/sub-tools/';
export const GetSubToolById = async (id: number) => {
    return axiosClient.get<SubTool>(`${GetSubToolByIdURL}${id}`);
};

export const GetSubToolVariablesBySubToolIdAndSendingTechnology = async (
    subToolId: number,
    sendingTechnology: SendingTechnologyTypeEnum
) => {
    return axiosClient.get<Array<CustomizationVariable>>(
        `tool/sub-tools/${subToolId}/template-variables/${sendingTechnology}`
    );
};

export const GetSubToolVariablesBySubToolId = async (subToolId: number) => {
    return axiosClient.get<Record<SubToolId, Array<CustomizationVariable>>>(
        `tool/sub-tools/${subToolId}/template-variables`
    );
};
// #endregion

// #region Subscription

export const GetCouponAttemptResultAsync = async (couponCode: string, periodicityInMonths: number) => {
    return axiosClient.get<CouponAttemptResult>(`subscription/coupon-attempt-result`, {
        params: {
            couponCode,
            periodicityInMonths
        }
    });
};

export type SubscriptionRequestType = {
    subscription: Subscription;
    isInterestedInMeuDimDim: boolean;
    payPalNonce?: string;
    hasAcceptedTheTerms: boolean;
};

export type SubscriptionResponseType = {
    subscription: Subscription;
    invoiceId?: number;
    bankSlipUrl?: string; // TODO: Verificar se pode remover
};

export const CreateSubscriptionRequest = async (data: SubscriptionRequestType) => {
    return axiosClient.post<SubscriptionResponseType>('subscription', data);
};

export const UpdateSubscriptionRequest = async (data: SubscriptionRequestType) => {
    return axiosClient.put<SubscriptionResponseType>('subscription', data);
};

export const GetSubscriptionRequest = async (subscriptionId?: number) => {
    return axiosClient.get<Subscription>(`subscription/${subscriptionId || ''}`);
};

export const GetCurrentSubscriptionByAuthenticatedUserAsync = async () => {
    return axiosClient.get<Subscription>(`subscription/customer`);
};

export const GetPendingInvoiceOfBankSlipTypeAsync = async (invoiceId: number) => {
    return axiosClient.get<SubscriptionInvoice>(`subscription/bank-slip/${invoiceId}`);
};

// #endregion

// #region Address

export const GetCountries = async () => {
    return axiosClient.get<Array<AddressCountry>>(`address/countries`);
};

export const GetCountryById = async (id: number) => {
    return axiosClient.get<AddressCountry>(`address/countries/${id}`);
};
// #endregion

// #region Payment

export const enum PaymentApiRoutes {
    CardFlags = 'payment/card-flag'
}

export const GetCardFlags = async (signal: AbortSignal = null) => {
    return axiosClient.get<Array<CardFlag>>(PaymentApiRoutes.CardFlags, { signal });
};

export const GetCardFlagById = async (id: number) => {
    return axiosClient.get<CardFlag>(`payment/card-flag/${id}`);
};

export const GetPaymentMethods = async () => {
    return axiosClient.get<Array<PaymentMethod>>(`payment/payment-method`);
};

export const GetPaymentMethodById = async (id: number) => {
    return axiosClient.get<PaymentMethod>(`payment/payment-method/${id}`);
};

export const GetBillingPeriods = async () => {
    return axiosClient.get<Array<BillingPeriod>>('payment/billing-period');
};

export const GetBillingPeriodById = async (id: number) => {
    return axiosClient.get<BillingPeriod>(`payment/billing-period/${id}`);
};

// #endregion

// #region File
export const DownloadFile = async (url: string) => {
    return axiosClient.get<Blob>(url, { responseType: 'blob' });
};
// #endregion

export const IsActivePainel2 = async () => {
    try {
        const response = await axios.get(PAINELN2_STATUS);

        return response.status === HttpStatusCodeEnum.OK;
    } catch (error) {
        return false;
    }
};

export const GetProductsForTemplateSubmissionTestingURL = '/store/products-for-template-submission-testing';
export const GetProductsForTemplateSubmissionTestingAsync = (quantityProducts = 10, signal: AbortSignal = null) => {
    return axiosClient.get<Product[]>(GetProductsForTemplateSubmissionTestingURL, {
        signal,
        params: {
            quantityProducts
        }
    });
};

export default axiosClient;
