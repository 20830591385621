import { useRef, useState } from 'react';
import { useRouter } from 'next/router';
import * as yup from 'yup';
import { Button } from '@components';
import { ContactConfiguration, SubToolConfiguration } from '@containers';
import { ConfirmCancellationDialog } from '@dialogs';
import { useSubToolPage, useTranslator } from '@hooks';
import {
    ContactConfigurationStatusEnum,
    SendingTechnologyTypeEnum,
    SubToolConfiguration as SubToolConfigurationModel
} from '@models';
import { useAlert } from '@providers';
import { captureException } from '@sentry/nextjs';
import type { FormHandles, SubmitHandler } from '@unform/core';
import { Form } from '@unform/web';
import { translateRoute } from '@utils';
import { routesName } from '~/locales/route';
import { EnviouApiResponseError } from '~/services/errors/enviouApiResponseError';
import { CreateSubToolConfigurationPayload, UpdateSubToolConfigurationAsync } from '~/services/subToolApiService';
import { PageTitle } from '~/styles/defaultComponentStyles';
import { SubToolColors } from '~/styles/theme';
import { SubToolConfigurationButtonGroupStyled, SubToolConfigurationStyled } from './styles';
import { hasChangesInForm, SubToolConfigurationFormData, useValidation } from './validation';

type SubToolConfigurationTemplateProps = {
    subToolConfiguration?: SubToolConfigurationModel;
};

export const SubToolConfigurationTemplate = ({ subToolConfiguration }: SubToolConfigurationTemplateProps) => {
    const {
        api: { statusError },
        buttons,
        subTools,
        dialogs: { success: successMessage }
    } = useTranslator();
    const formRef = useRef<FormHandles>();
    const subToolConfigurationRef = useRef<SubToolConfigurationModel>(subToolConfiguration);
    const [isLoading, setIsLoading] = useState(false);
    const [showCancellationModal, setShowCancellationModal] = useState(false);
    const { getSubToolPageData } = useSubToolPage();
    const { success, error } = useAlert();

    const { subToolConfigurationSchema } = useValidation(subToolConfiguration.subTool.id);
    const router = useRouter();

    const redirectToSubToolPage = async () => {
        const routePath = routesName.tool.automationTool;
        const newRoute = routesName.subTool.subTool;
        const {
            locale,
            query: { subTool }
        } = router;
        const translatedRoutePath = translateRoute(newRoute, locale, subTool);

        await router.push({ pathname: routePath, query: { subTool } }, translatedRoutePath);
    };

    const handleSubmit: SubmitHandler<SubToolConfigurationFormData> = async (formData) => {
        try {
            setIsLoading(true);
            formRef.current.setErrors({});

            await subToolConfigurationSchema.validate(formData, { abortEarly: false });

            const payload: CreateSubToolConfigurationPayload = {
                id: subToolConfiguration.id,
                status: formData.status,
                repurchaseConsideredPeriod: formData.repurchaseConsideredPeriod,
                subToolId: subToolConfiguration.subTool.id,
                contactConfigurations: formData.contactConfigurations.map((contact) => ({
                    id: contact?.contactConfigurationId ?? undefined,
                    subToolConfigurationId: subToolConfiguration.id,
                    senderName: contact.senderName,
                    senderContact: contact.senderContact,
                    contactAnswer: contact.contactAnswer,
                    sendingTechnology: contact?.sendingTechnology,
                    status: ContactConfigurationStatusEnum.Active
                }))
            };

            await UpdateSubToolConfigurationAsync(payload)
                .then((response) => {
                    setIsLoading(false);
                    subToolConfigurationRef.current = response.data;
                    success(String(successMessage.savedSubToolConfiguration));
                    return redirectToSubToolPage();
                })
                .catch((exception) => {
                    setIsLoading(false);
                    captureException(exception);

                    const errorMessage =
                        exception instanceof EnviouApiResponseError
                            ? exception.getFirstError('updateSubToolErrorStatus').message
                            : String(statusError.systemErrorStatus.internalError);

                    error(errorMessage);
                });
        } catch (exception) {
            if (exception instanceof yup.ValidationError) {
                const errorMessages = {};

                exception.inner.forEach((error) => (errorMessages[error.path] = error.message));
                formRef.current.setErrors(errorMessages);
            } else {
                captureException(exception);
            }

            setIsLoading(false);
        }
    };

    const handleCancelButton = () => {
        const formData = formRef.current.getData() as SubToolConfigurationFormData;
        if (hasChangesInForm(formData, subToolConfigurationRef.current)) {
            setShowCancellationModal(true);

            return;
        }
        redirectToSubToolPage();
    };

    const { subToolKey, translatedSubToolName } = getSubToolPageData(subToolConfiguration.subTool.id);

    return (
        <Form ref={formRef} onSubmit={handleSubmit} initialData={subToolConfiguration}>
            <SubToolConfigurationStyled>
                <PageTitle>{translatedSubToolName}</PageTitle>
                <SubToolConfiguration
                    subToolConfigurationStatus={subToolConfiguration.status}
                    subTool={subToolConfiguration.subTool}
                    subToolColor={subToolKey as SubToolColors}
                />
                <ContactConfiguration
                    allowedSendingTechnologies={[SendingTechnologyTypeEnum.Email]}
                    contacts={subToolConfiguration.contactConfigurations}
                    subToolColor={subToolKey as SubToolColors}
                    showIsActiveCheckbox={false}
                />
                <SubToolConfigurationButtonGroupStyled>
                    <ConfirmCancellationDialog
                        options={{ open: showCancellationModal, onOpenChange: setShowCancellationModal }}
                        triggerElement={
                            <Button color='red' buttonSize='small' isOutlined onClick={handleCancelButton}>
                                {String(buttons.cancel)}
                            </Button>
                        }
                        title={String(subTools.subToolConfiguration.messageOnExit)}
                        onYesClick={redirectToSubToolPage}
                        onToBackClick={() => setShowCancellationModal(false)}
                    />

                    <Button color='purple' buttonSize='small' type='submit' inLoading={isLoading}>
                        {String(buttons.save)}
                    </Button>
                </SubToolConfigurationButtonGroupStyled>
            </SubToolConfigurationStyled>
        </Form>
    );
};
