import { ChangeEvent, MutableRefObject, useMemo, useState } from 'react';
import debounce from 'lodash/debounce';
import {
    Divider,
    ExpandableGroup,
    InputFormGroup,
    InputGroup,
    Label,
    ModelsTemplateEditor,
    PushTemplatePreview,
    SelectFormGroup,
    TextareaFormGroup
} from '@components';
import { VariableParentKey, useTemplate, useTranslator } from '@hooks';
import {
    CustomizationVariable,
    PushActionContent,
    PushNotificationContent,
    SendingTechnologyTypeEnum,
    SubTool
} from '@models';
import { useAlert, useAuth } from '@providers';
import { captureException } from '@sentry/nextjs';
import { FormHandles } from '@unform/core';
import { MAX_SMS_MESSAGE_SIZE, getFilenameFromURL, isDevelopment } from '@utils';
import { ACCEPT_IMAGES } from '~/components/templates/sendingTechnology/pushConfiguration/validation';
import { EnviouApiResponseError } from '~/services/errors/enviouApiResponseError';
import { SavePushContentImage, SendWebPushNotificationTestAsync } from '~/services/pushConfigurationServiceApi';
import { registerPushNotifications } from '~/utils/notifications/pushService';
import { PushActionWrapper, SendPushTestButtonStyled } from './styles';

type PushTemplateEditorProps = {
    subTool?: SubTool;
    formRef: MutableRefObject<FormHandles>;
    initialData?: PushNotificationContent;
    inputName?: string;
    customizationVariables?: CustomizationVariable[];
    isDefaultTemplate?: boolean;
    subToolKeyIdentifier: VariableParentKey;
    isReduced?: boolean;
    onChangeEnableTechnology?: (checked: boolean, sendingTechnologyType: SendingTechnologyTypeEnum) => void;
    enableTechnology?: boolean;
    subToolColor?: string;
};

const WAIT_TIME_IN_MILLISECONDS_TO_EXECUTE_ON_CHANGE = 600;
const INPUT_NAME = 'pushNotification';

export const PushTemplateEditor = ({
    formRef,
    initialData,
    inputName,
    customizationVariables = [],
    subToolKeyIdentifier,
    onChangeEnableTechnology,
    subToolColor,
    enableTechnology = true
}: PushTemplateEditorProps) => {
    const {
        buttons,
        dialogs: { errors },
        subTools: {
            pushTemplateEditor: {
                title,
                subtitle,
                pushTitlePlaceholder,
                content,
                contentPlaceholder,
                addImage,
                addImagePlaceholder,
                link,
                pushActions,
                pushActionConfiguration
            }
        }
    } = useTranslator();
    type pushActionType = keyof typeof pushActions;

    const [pushTitle, setTitle] = useState(initialData?.title ?? '');
    const [selectedPushAction, setSelectedPushAction] = useState<pushActionType>(() => {
        if (!initialData?.actions?.length) {
            return 'click';
        }

        if (initialData?.actions?.length === 1) {
            return 'pushOneButton';
        }

        return 'pushTwoButtons';
    });

    const [redirectUrl, setRedirectUrl] = useState(initialData?.redirectUrl ?? '');
    const [oneButtonData, setOneButtonData] = useState<{
        link: string;
        text: string;
    }>({
        link: initialData?.actions?.[0]?.redirectUrl ?? '',
        text: initialData?.actions?.[0]?.title ?? ''
    });

    const [twoButtonsData, setTwoButtonsData] = useState<{
        left: {
            link: string;
            text: string;
        };
        right: {
            link: string;
            text: string;
        };
    }>({
        left: {
            link: initialData?.actions?.[0]?.redirectUrl ?? '',
            text: initialData?.actions?.[0]?.title ?? ''
        },
        right: {
            link: initialData?.actions?.[1]?.redirectUrl ?? '',
            text: initialData?.actions?.[1]?.title ?? ''
        }
    });

    const [file, setFile] = useState<File>(null);
    const [imageUrl, setImageUrl] = useState<string>(initialData?.image ?? '');
    const [uploadedImage, setUploadedImage] = useState<string>(null);
    const [isSendingPushNotification, setIsSendingPushNotification] = useState(false);
    const filename = file?.name ?? getFilenameFromURL(imageUrl) ?? '';
    const { generateTemplatePreviewBySendingTechnology } = useTemplate(subToolKeyIdentifier);
    const { error } = useAlert();
    const { user } = useAuth();
    inputName = inputName ?? `${INPUT_NAME}`;
    const sendingTechnology = SendingTechnologyTypeEnum.Push;

    const variables = useMemo(() => {
        let variables = [];
        customizationVariables?.forEach((item) => {
            if (item) variables = [...variables, ...item.variables.map((variable) => variable?.toLowerCase())];
        });

        return variables.filter(Boolean);
    }, [customizationVariables]);

    const [formattedMessage, setFormattedMessage] = useState(() =>
        generateTemplatePreviewBySendingTechnology({
            user,
            baseHtml: initialData?.body,
            sendingTechnology: sendingTechnology,
            allowedVariables: variables
        })
    );

    const handleTextChange = debounce((text: string) => {
        const preview = generateTemplatePreviewBySendingTechnology({
            user,
            baseHtml: text,
            sendingTechnology: sendingTechnology,
            allowedVariables: variables
        });

        setFormattedMessage(preview);
    }, WAIT_TIME_IN_MILLISECONDS_TO_EXECUTE_ON_CHANGE);

    const onChangeEnabled = (checked: boolean) => {
        onChangeEnableTechnology(checked, sendingTechnology);
    };

    const handleFileSelected = (event: ChangeEvent<HTMLInputElement>) => {
        const { files } = event.target;

        if (files) {
            const currentFile = files[0];

            if (currentFile?.size > 0) {
                const reader = new FileReader();
                reader.onload = (event) => setImageUrl(event.target.result as string);
                reader.readAsDataURL(currentFile);
                setUploadedImage(null);
            } else {
                setImageUrl(initialData?.image ?? '');
                formRef.current.clearField(inputName);
            }

            setFile(currentFile);
        }
    };

    const pushActionsList = Object.keys(pushActions).map((key) => ({
        id: key,
        label: pushActions[key] as string
    }));

    const pushNotificationActionViewOnList: { [key: string]: PushActionContent[] } = {
        click: [
            {
                redirectUrl: redirectUrl
            }
        ],
        pushOneButton: [
            {
                title: oneButtonData.text,
                action: 'left',
                type: 'button',
                redirectUrl: oneButtonData.link
            }
        ],
        pushTwoButtons: [
            {
                title: twoButtonsData.left.text,
                action: 'left',
                type: 'button',
                redirectUrl: twoButtonsData.left.link
            },
            {
                title: twoButtonsData.right.text,
                action: 'right',
                type: 'button',
                redirectUrl: twoButtonsData.right.link
            }
        ]
    };

    const handleChangeActionViewOn = (pushactionType: pushActionType) => {
        if (redirectUrl) {
            setRedirectUrl('');
        }

        if (oneButtonData) {
            setOneButtonData({
                text: '',
                link: ''
            });
        }

        if (twoButtonsData) {
            setTwoButtonsData({
                left: {
                    text: '',
                    link: ''
                },
                right: {
                    text: '',
                    link: ''
                }
            });
        }

        formRef.current.setErrors({});

        setSelectedPushAction(pushactionType);
    };

    const sendTestNotification = async () => {
        try {
            setIsSendingPushNotification(true);

            const currentPushSubscription = await registerPushNotifications();
            const pushSubscription = currentPushSubscription.toJSON() as PushSubscriptionJSON;

            const pushNotificationContent = {
                title: pushTitle,
                body: formattedMessage,
                image: uploadedImage ?? imageUrl,
                redirectUrl: redirectUrl,
                icon: formRef.current?.getFieldValue(`${inputName}.icon`) || initialData?.icon,
                actions: selectedPushAction !== 'click' ? pushNotificationActionViewOnList[selectedPushAction] : null
            } as PushNotificationContent;

            if (!uploadedImage && file) {
                const imageFormData = new FormData();
                imageFormData.set('image', file as File);
                const uploadedImage = await SavePushContentImage(imageFormData);
                pushNotificationContent.image = uploadedImage?.data;
                setUploadedImage(uploadedImage?.data);
            }

            await SendWebPushNotificationTestAsync(pushSubscription, pushNotificationContent);

            setIsSendingPushNotification(false);
        } catch (_error) {
            isDevelopment() && console.error(_error);
            if (_error instanceof EnviouApiResponseError) {
                error(_error.message);
            } else {
                error(String(errors.failedToSendTestPush.title), (_error as Error)?.message);
            }
            captureException(_error);
            setIsSendingPushNotification(false);
        }
    };

    return (
        <ModelsTemplateEditor
            key={`${INPUT_NAME}EDITOR`}
            customizationVariables={customizationVariables}
            hasCustomizationVariables={variables.length > 0}
            subToolKeyIdentifier={subToolKeyIdentifier}
            subToolColor={subToolColor}
            subtitle={String(subtitle)}
            title={String(title)}
            onChangeEnableTechnology={onChangeEnabled}
            enableTechnology={enableTechnology}
            isCheckSecurityModalOpen={false}
            sendingTechnology={sendingTechnology}
            formComponents={
                <>
                    <InputFormGroup
                        type='text'
                        autoComplete='off'
                        inputSize='small'
                        placeholder={String(pushTitlePlaceholder)}
                        name={`${inputName}.title`}
                        testId={`${inputName}Title`}
                        onChange={(event) => setTitle(event.target.value)}
                    />
                    <span>
                        {pushTitle?.length ?? 0}/{MAX_SMS_MESSAGE_SIZE}
                    </span>
                    <Label>{content}</Label>

                    <TextareaFormGroup
                        name={`${inputName}.body`}
                        cols={30}
                        rows={6}
                        defaultValue={formattedMessage}
                        onChange={(event) => handleTextChange(event.target.value)}
                        placeholder={String(contentPlaceholder)}
                    />

                    <Label>{addImage}</Label>
                    <InputGroup
                        accept={ACCEPT_IMAGES.join(',')}
                        buttonText={String(buttons.chooseFile)}
                        filename={filename}
                        inputType='file'
                        inputName={`${inputName}.image`}
                        inputPlaceHolder={String(addImagePlaceholder)}
                        onChange={handleFileSelected}
                    />

                    <SelectFormGroup
                        inputSize='small'
                        name={`${inputName}.actionViewOn`}
                        options={pushActionsList}
                        defaultValue={selectedPushAction}
                        label={String(pushActionConfiguration.title)}
                        testId='dropdown__viewOn'
                        onOptionChange={({ id }) => handleChangeActionViewOn(id as pushActionType)}
                    />
                    {selectedPushAction === 'click' && (
                        <InputFormGroup
                            label={link}
                            type='text'
                            autoComplete='off'
                            inputSize='small'
                            placeholder={String(pushActionConfiguration.linkPlaceholder)}
                            name={`${inputName}.redirectUrl`}
                            testId={`${inputName}RedirectUrl`}
                            value={redirectUrl}
                            onChange={(event) => setRedirectUrl(event.target.value)}
                        />
                    )}

                    <ExpandableGroup isExpanded={selectedPushAction !== 'click'}>
                        <PushActionWrapper>
                            {selectedPushAction === 'pushOneButton' && (
                                <>
                                    <InputFormGroup
                                        label={String(pushActionConfiguration.buttonText)}
                                        type='text'
                                        autoComplete='off'
                                        placeholder={String(pushActionConfiguration.buttonText)}
                                        name={`${inputName}.oneButton.text`}
                                        testId={`${inputName}oneButtonText`}
                                        value={oneButtonData.text}
                                        onChange={(event) =>
                                            setOneButtonData({ ...oneButtonData, text: event.target.value })
                                        }
                                    />
                                    <InputFormGroup
                                        label={link}
                                        type='text'
                                        autoComplete='off'
                                        placeholder={String(pushActionConfiguration.linkPlaceholder)}
                                        name={`${inputName}.oneButton.link`}
                                        testId={`${inputName}oneButtonLink`}
                                        value={oneButtonData.link}
                                        onChange={(event) =>
                                            setOneButtonData({ ...oneButtonData, link: event.target.value })
                                        }
                                    />
                                </>
                            )}

                            {selectedPushAction === 'pushTwoButtons' && (
                                <>
                                    <InputFormGroup
                                        label={String(pushActionConfiguration.leftButton)}
                                        type='text'
                                        autoComplete='off'
                                        placeholder={String(pushActionConfiguration.leftButton)}
                                        name={`${inputName}.twoButtons.left.text`}
                                        testId={`${inputName}twoButtonsLeftText`}
                                        value={twoButtonsData.left.text}
                                        onChange={(event) =>
                                            setTwoButtonsData({
                                                ...twoButtonsData,
                                                left: { ...twoButtonsData.left, text: event.target.value }
                                            })
                                        }
                                    />
                                    <InputFormGroup
                                        label={link}
                                        type='text'
                                        autoComplete='off'
                                        placeholder={String(pushActionConfiguration.linkPlaceholder)}
                                        name={`${inputName}.twoButtons.left.link`}
                                        testId={`${inputName}twoButtonsLeftLink`}
                                        value={twoButtonsData.left.link}
                                        onChange={(event) =>
                                            setTwoButtonsData({
                                                ...twoButtonsData,
                                                left: { ...twoButtonsData.left, link: event.target.value }
                                            })
                                        }
                                    />
                                    <Divider />

                                    <InputFormGroup
                                        label={String(pushActionConfiguration.rightButton)}
                                        type='text'
                                        autoComplete='off'
                                        placeholder={String(pushActionConfiguration.rightButton)}
                                        name={`${inputName}.twoButtons.right.text`}
                                        testId={`${inputName}twoButtonsRightText`}
                                        value={twoButtonsData.right.text}
                                        onChange={(event) =>
                                            setTwoButtonsData({
                                                ...twoButtonsData,
                                                right: { ...twoButtonsData.right, text: event.target.value }
                                            })
                                        }
                                    />
                                    <InputFormGroup
                                        label={link}
                                        type='text'
                                        autoComplete='off'
                                        placeholder={String(pushActionConfiguration.linkPlaceholder)}
                                        name={`${inputName}.twoButtons.right.link`}
                                        testId={`${inputName}twoButtonsRightLink`}
                                        value={twoButtonsData.right.link}
                                        onChange={(event) =>
                                            setTwoButtonsData({
                                                ...twoButtonsData,
                                                right: { ...twoButtonsData.right, link: event.target.value }
                                            })
                                        }
                                    />
                                </>
                            )}

                            <InputFormGroup
                                type='text'
                                id='pushPreview'
                                name='pushPreview'
                                value={formattedMessage}
                                containerClassName='hidden'
                            />
                        </PushActionWrapper>
                    </ExpandableGroup>
                    <SendPushTestButtonStyled
                        inLoading={isSendingPushNotification}
                        color='purple'
                        onClick={sendTestNotification}>
                        {String(buttons.sendPushNotificationTest)}
                    </SendPushTestButtonStyled>
                </>
            }
            modelPreview={
                <PushTemplatePreview
                    body={formattedMessage}
                    title={pushTitle}
                    redirectUrl={user?.storeUrl || ''}
                    imageUrl={imageUrl}
                    iconUrl={formRef.current?.getFieldValue(`${inputName}.icon`) || initialData?.icon}
                    selectedPushAction={selectedPushAction}
                    pushActionList={pushNotificationActionViewOnList}
                />
            }
        />
    );
};
