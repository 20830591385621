import { useMemo, useRef, useState } from 'react';
import { minutesToMilliseconds } from 'date-fns';
import { useRouter } from 'next/router';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    CardText,
    Loading,
    SelectFormGroup,
    type SelectData,
    type SelectDataType
} from '@components';
import { useFetch, useTranslator } from '@hooks';
import { WhatsAppConfiguration, FacebookUserData, WhatsAppPhoneNumber, WhatsAppBusinessAccount } from '@models';
import { useAlert } from '@providers';
import { captureException } from '@sentry/nextjs';
import { FormHandles, SubmitHandler } from '@unform/core';
import PhoneIcon from '~/assets/svg/icons/icon__phone.svg';
import WhatsAppIcon from '~/assets/svg/icons/sendingTechnologies/icon__whatsapp.svg';
import { FacebookWhite } from '~/assets/svg/social';
import { ImageProfile } from '~/components/elements/header/imageProfile';
import { routesName } from '~/locales/route';
import { GetFacebookAuthenticationUrl } from '~/services/sessionsServiceApi';
import {
    SaveWhatsAppConfigurationAsync,
    GetPhoneNumbersAsync,
    getPhoneNumbersUrl
} from '~/services/whatsAppBusinessApiService';
import { PageTitle } from '~/styles';
import { FormStyled, FormContentStyled, FacebookButton } from './styles';

export type WhatsAppConfigurationTemplateProps = {
    configuration: WhatsAppConfiguration;
    facebookUserInfo: FacebookUserData;
    whatsAppBusinessAccounts: WhatsAppBusinessAccount[];
    phoneNumbers: WhatsAppPhoneNumber[];
};

type WhatsAppConfigurationFormData = {
    accountId?: string;
    phoneNumberId?: string;
};

const MINUTES_TO_RELOAD_PHONE_NUMBERS = 30;
const WHATSAPP_SCOPES = ['whatsapp_business_messaging', 'whatsapp_business_management'];

export const WhatsAppConfigurationTemplate = ({
    configuration: defaultConfiguration,
    facebookUserInfo,
    whatsAppBusinessAccounts,
    phoneNumbers: defaultPhoneNumbers
}: WhatsAppConfigurationTemplateProps) => {
    const router = useRouter();
    const formRef = useRef<FormHandles>();
    const { success, error } = useAlert();
    const [isLoading, setIsLoading] = useState(false);
    const [configuration, setConfiguration] = useState<WhatsAppConfigurationFormData>({
        accountId: defaultConfiguration.facebookManagerId,
        phoneNumberId: defaultConfiguration.whatsAppBusinessPhone
    });
    const {
        buttons,
        pages: {
            sendingTechnologySetting: {
                title: pageTitle,
                whatsAppConfiguration: { auth, configuration: setup, alerts }
            }
        }
    } = useTranslator();

    const {
        data: phoneNumbers,
        isLoading: isPhoneNumbersLoading,
        isValidating: isPhoneNumbersValidating
    } = useFetch(
        configuration.accountId && {
            url: getPhoneNumbersUrl(configuration.accountId),
            accountId: configuration.accountId
        },
        async (key, signal) => (await GetPhoneNumbersAsync(key.accountId, signal)).data,
        {
            dedupingInterval: minutesToMilliseconds(MINUTES_TO_RELOAD_PHONE_NUMBERS),
            keepPreviousData: true,
            fallbackData: defaultPhoneNumbers,
            onError: () => {
                error(String(alerts.failedTitle), String(alerts.phoneNumbers.failed));
            }
        }
    );

    const accountOptions = useMemo<SelectData[]>(
        () => whatsAppBusinessAccounts.map<SelectData>((account) => ({ id: account.id, label: account.name })),
        [whatsAppBusinessAccounts]
    );

    const phoneNumbersOptions = useMemo(
        () =>
            phoneNumbers.map<SelectData>((phoneNumber) => ({
                id: phoneNumber.id,
                label: `${phoneNumber.displayPhoneNumber} (${
                    setup.phoneNumber.status[phoneNumber.status ?? 'UNDEFINED']
                })`
            })),
        [phoneNumbers]
    );

    const handleAuthFacebook = async () => {
        const response = await GetFacebookAuthenticationUrl(window.location.href, WHATSAPP_SCOPES, true);

        await router.push(response.data);
    };

    const handleAccountChange = (selected: SelectDataType) => {
        setConfiguration((prevConfig) => ({ ...prevConfig, accountId: String(selected.id) }));

        formRef.current.clearField('phoneNumberId');
    };

    const handleSubmit: SubmitHandler<WhatsAppConfigurationFormData> = (data) => {
        setIsLoading(true);

        SaveWhatsAppConfigurationAsync(data.accountId, data.phoneNumberId)
            .then((response) => {
                setConfiguration({
                    accountId: response.data.facebookManagerId,
                    phoneNumberId: response.data.whatsAppBusinessPhone
                });

                success(String(alerts.success), null, {
                    onClose: async () => await router.push(routesName.root)
                });
            })
            .catch((exception) => {
                error(String(alerts.failedTitle), String(alerts.failed));
                captureException(exception);
            })
            .finally(() => setIsLoading(false));
    };

    return (
        <FormStyled ref={formRef} onSubmit={handleSubmit} initialData={configuration}>
            <PageTitle>{String(pageTitle)}</PageTitle>
            <Card type='group'>
                <CardHeader title={String(auth.title)} />
                <CardBody>
                    <FormContentStyled>
                        <CardText type='subtitle' value={String(auth.description)} />

                        <FacebookButton
                            type='button'
                            color='blue'
                            onClick={handleAuthFacebook}
                            className='facebook-button'>
                            {facebookUserInfo.id ? (
                                <>
                                    <ImageProfile
                                        src={facebookUserInfo.picture}
                                        alt={`${facebookUserInfo.name} profile picture`}
                                        width='2.5rem'
                                        height='2.5rem'
                                        unoptimized
                                    />
                                    <span className='facebook-button__text'>{String(facebookUserInfo.name)}</span>
                                </>
                            ) : (
                                <>
                                    <FacebookWhite title='Facebook' />
                                    <span className='facebook-button__text'>{String(auth.button)}</span>
                                </>
                            )}
                        </FacebookButton>
                    </FormContentStyled>
                </CardBody>
            </Card>

            {facebookUserInfo?.id && (
                <>
                    <Card type='group'>
                        <CardHeader title={String(setup.title)} />
                        <CardBody>
                            <FormContentStyled>
                                <SelectFormGroup
                                    name='accountId'
                                    icon={<WhatsAppIcon title='WhatsApp Icon' width='3rem' height='3rem' />}
                                    label={String(setup.account.label)}
                                    placeholder={String(setup.account.placeholder)}
                                    options={accountOptions}
                                    onOptionChange={handleAccountChange}
                                />

                                <SelectFormGroup
                                    name='phoneNumberId'
                                    icon={
                                        isPhoneNumbersLoading || isPhoneNumbersValidating ? (
                                            <Loading inLoading={true} size='small' />
                                        ) : (
                                            <PhoneIcon title='Phone Icon' width='3rem' height='2rem' />
                                        )
                                    }
                                    label={String(setup.phoneNumber.label)}
                                    placeholder={String(setup.phoneNumber.placeholder)}
                                    options={phoneNumbersOptions}
                                    disabled={
                                        isPhoneNumbersLoading || isPhoneNumbersValidating || !configuration.accountId
                                    }
                                />
                            </FormContentStyled>
                        </CardBody>
                    </Card>

                    <Button
                        type='submit'
                        color='purple'
                        inLoading={isLoading || isPhoneNumbersLoading || isPhoneNumbersValidating}
                        disabled={
                            isLoading || isPhoneNumbersLoading || isPhoneNumbersValidating || !configuration.accountId
                        }>
                        {String(buttons.save)}
                    </Button>
                </>
            )}
        </FormStyled>
    );
};
