import { useState } from 'react';
import { useTranslator } from '@hooks';
import { ConfirmDialogButtonProps, ConfirmDialogProps } from './confirmDialog';
import { ConfirmCancellationDialogStyled } from './styles';

type ConfirmCancellationDialogProps = ConfirmDialogProps & {
    onToBackClick?: () => void;
    onYesClick?: () => Promise<void> | void;
    isLoading?: boolean;
};

export const ConfirmCancellationDialog = ({
    onToBackClick,
    onYesClick,
    isLoading: initialLoading,
    ...props
}: ConfirmCancellationDialogProps) => {
    const { yes, toGoBack } = useTranslator().buttons;
    const [isLoading, setIsLoading] = useState(initialLoading);

    const handleYes = async () => {
        setIsLoading(true);

        try {
            await onYesClick();
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const buttons: Array<ConfirmDialogButtonProps> = [
        {
            title: String(toGoBack),
            onClick: onToBackClick,
            color: 'purple',
            isOutlined: true
        },
        {
            title: String(yes),
            color: 'purple',
            onClick: handleYes,
            inLoading: isLoading,
            disabled: isLoading
        }
    ];
    return <ConfirmCancellationDialogStyled buttons={buttons} {...props} />;
};
