import { MouseEventHandler, ReactNode, useState } from 'react';
import { KeenSliderOptions, useKeenSlider, KeenSliderPlugin } from 'keen-slider/react';
import { Loading } from '@components';
import { MutationPlugin, ResizePlugin } from './plugins';
import { ArrowLeftIconStyled, ArrowRightIconStyled, CarouselContainerStyled } from './styles';

type CarouselProps = {
    name: string;
    children: ReactNode;
    isLoading?: boolean;
    settings?: KeenSliderOptions;
    enableArrow?: boolean;
    plugins?: Array<KeenSliderPlugin>;
};

export const getSliderSettings = (): KeenSliderOptions => ({
    initial: 0,
    slides: {
        perView: 5,
        spacing: 20
    },
    mode: 'free',
    breakpoints: {
        '(max-width: 1008px)': {
            slides: {
                perView: 5,
                spacing: 20
            }
        },
        '(max-width: 997px)': {
            slides: {
                perView: 4,
                spacing: 20
            }
        },
        '(max-width: 767px)': {
            slides: {
                perView: 3,
                spacing: 20
            }
        },
        '(max-width: 640px)': {
            slides: {
                perView: 2,
                spacing: 16
            }
        },
        '(max-width: 480px)': {
            slides: {
                perView: 1,
                spacing: 16
            }
        }
    }
});

export const Carousel = ({
    children,
    isLoading = false,
    settings = getSliderSettings(),
    enableArrow = true,
    plugins = [MutationPlugin, ResizePlugin],
    ...props
}: CarouselProps) => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const [loaded, setLoaded] = useState(false);
    const [maxIdx, setMaxIdx] = useState(0);

    const sliderConfig: KeenSliderOptions = {
        ...settings,
        slideChanged(slider) {
            settings.slideChanged && settings.slideChanged(slider);
            setCurrentSlide(slider.track.details.rel);
        },
        created(slider) {
            settings.created && settings.created(slider);
            setLoaded(true);
            setMaxIdx(slider?.track?.details?.maxIdx ?? 0);
        },
        detailsChanged(slider) {
            settings.detailsChanged && settings.detailsChanged(slider);
            setMaxIdx(slider?.track?.details?.maxIdx ?? 0);
        }
    };

    const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>(sliderConfig, plugins);

    const handlePrevSlide: MouseEventHandler<SVGSVGElement> = (event) => {
        event.stopPropagation();
        instanceRef.current?.prev();
    };

    const handleNextSlide: MouseEventHandler<SVGSVGElement> = (event) => {
        event.stopPropagation();
        instanceRef.current?.next();
    };

    const showArrows = loaded && instanceRef.current && maxIdx > 0 && enableArrow;
    const initialSpacing =
        loaded && instanceRef.current ? 0 : (sliderConfig?.slides as { spacing: number })?.spacing ?? 0;

    return (
        <CarouselContainerStyled isLoading={isLoading} initialSpacing={initialSpacing} {...props}>
            <Loading size='large' inLoading={isLoading} />

            <div ref={sliderRef} className='keen-slider'>
                {children}
            </div>

            {showArrows && (
                <>
                    <ArrowLeftIconStyled
                        onClick={handlePrevSlide}
                        disabled={currentSlide === 0}
                        className='keen-slider__left-arrow'
                    />
                    <ArrowRightIconStyled
                        onClick={handleNextSlide}
                        disabled={currentSlide === instanceRef.current?.track?.details?.maxIdx}
                        className='keen-slider__right-arrow'
                    />
                </>
            )}
        </CarouselContainerStyled>
    );
};
