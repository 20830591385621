import { ShoppingCartItem, TransactionStatus } from '@models';

export const enum ExportStatisticsSelection {
    Csv,
    Json
}

export type GeneralDetailedStatistics = {
    transactionItemId: string;
    date: Date;
    shopperName: string;
    shopperEmail: string;
    shopperMobile: string;
    subToolId: number;
    customTriggerName?: string;

    status: TransactionStatus;
    errorMessage?: string;
    totalSalesValue?: number;
    currency: string;

    products?: Array<ShoppingCartItem>;
    cartId?: string;
    cartStatus?: number;
    cartOrderId?: string;
};

export enum DetailedStatisticsStatus {
    InProgress = 1,
    Sent = 2,
    GeneratedSales = 3,
    Rejected = 4,
    CancelledByPurchase = 11,
    CancelledByEndOfNavigation = 16,
    Error = 99
}
