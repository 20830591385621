import styled from 'styled-components';

export const SendingTechnologyStyled = styled.div`
    display: grid;
    align-items: center;
    grid-template-columns: repeat(5, 1fr);
    gap: 1rem;

    svg {
        color: ${({ theme }) => theme.colors.purple};
    }

    *:not(:first-child) {
        text-align: center;
    }

    & + div:not(:nth-child(2)) {
        margin-top: 1.6rem;
    }
`;
