import { useState } from 'react';
import { CardHeader, Button, CardBody, Heading, CodeBlock, InfoTooltip } from '@components';
import { ConfirmCancellationDialog, ConfirmDeletionDialog } from '@dialogs';
import { useTranslator } from '@hooks';
import { useAlert } from '@providers';
import { RegenerateToken, externalSubmitContactListUrl } from '~/services/customTriggerApiService';
import { PageTitle } from '~/styles';
import { CardStyled, ContainerStyled, TooltipContentStyled, ActionButtonsContainerStyled } from './styles';

const CodeBlockHeader = ({ title, tooltip }) => {
    return (
        <Heading as='h6'>
            {title}
            <InfoTooltip>
                <TooltipContentStyled>{tooltip}</TooltipContentStyled>
            </InfoTooltip>
        </Heading>
    );
};

const requestExample = [
    {
        email: 'enviou@enviou.com.br',
        name: 'enviou',
        cellPhone: '81999999999',
        infoAdd1: 'Informação adicional primario',
        infoAdd2: 'Informação adicional secundario'
    }
];

const responseExample = { success: true, message: 'Opcional quando houver erro' };

export type ConfigureTriggerTemplateProps = {
    triggerToken: string;
    sequenceId: number;
};

export const ConfigureTriggerTemplate = ({ triggerToken, sequenceId }: ConfigureTriggerTemplateProps) => {
    const { show, success, error } = useAlert();
    const [token, setToken] = useState(triggerToken);
    const {
        enums,
        buttons,
        pages,
        dialogs: { errors, successes }
    } = useTranslator();
    const {
        confirmDeletion,
        confirmRegenerateToken,
        configureTrigger: { title, content }
    } = pages.subTool.customTrigger;

    const handleDeleteTrigger = () => {
        show(String(confirmDeletion), null, {
            content: ({ title, options }) => (
                <ConfirmDeletionDialog
                    title={title}
                    options={options}
                    onToBackClick={() => options.onOpenChange(false)}
                    onDeleteClick={() => options.onOpenChange(false)}
                />
            )
        });
    };

    const handleRegenerateToken = () => {
        show(String(confirmRegenerateToken), null, {
            content: ({ title, options }) => (
                <ConfirmCancellationDialog
                    title={title}
                    options={options}
                    onToBackClick={() => options.onOpenChange(false)}
                    onYesClick={async () => {
                        await RegenerateToken(sequenceId)
                            .then((response) => {
                                setToken(response.data);
                                success(String(successes.successfullyTriggerTokenRegenerated));
                            })
                            .catch(() => error(String(errors.failedRegenerateTriggerToken)));

                        options.onOpenChange(false);
                    }}
                />
            )
        });
    };

    const submitEndpoint = new URL(externalSubmitContactListUrl, process.env.NEXT_PUBLIC_ENVIOU_API);
    submitEndpoint.searchParams.append('token', token ?? '');

    return (
        <ContainerStyled>
            <PageTitle>{String(enums.subToolIdEnum.CustomTrigger)}</PageTitle>
            <CardStyled type='group'>
                <CardHeader title={String(title)} />
                <CardBody>
                    <div>
                        <CodeBlockHeader
                            title={String(content.postUrl.title)}
                            tooltip={String(content.postUrl.tooltip)}
                        />
                        <CodeBlock>{submitEndpoint.toString()}</CodeBlock>
                    </div>
                    <div>
                        <CodeBlockHeader
                            title={String(content.requestFormat.title)}
                            tooltip={String(content.requestFormat.tooltip)}
                        />
                        <CodeBlock>{JSON.stringify(requestExample, null, '  ')}</CodeBlock>
                    </div>
                    <div>
                        <CodeBlockHeader
                            title={String(content.responseFormat.title)}
                            tooltip={String(content.responseFormat.tooltip)}
                        />
                        <CodeBlock>{JSON.stringify(responseExample, null, '  ')}</CodeBlock>
                    </div>
                </CardBody>
            </CardStyled>
            <ActionButtonsContainerStyled>
                <Button buttonSize='small' color='red' isOutlined onClick={handleDeleteTrigger}>
                    {String(buttons.deleteTrigger)}
                </Button>
                <Button buttonSize='small' color='purple' onClick={handleRegenerateToken}>
                    {String(buttons.regenerateToken)}
                </Button>
            </ActionButtonsContainerStyled>
        </ContainerStyled>
    );
};
