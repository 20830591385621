import { ChangeEventHandler, useMemo } from 'react';
import { useTranslator } from '@hooks';
import { SubToolTemplateConfigurationFrequencyRange, SubToolsEventTriggers } from '@models';
import { GetTranslatedEnumName, isGreaterThanOne } from '@utils';
import { Card, CardBody, CardHeader, InfoTooltip, Label, SelectData } from '~/components/elements';
import { InputFormGroup, SelectFormGroup } from '~/components/forms';
import Locales from '~/locales/locales.json';
import { Content, TooltipContentStyled, PeriodContentStyled } from './styles';

export type FrequencySelectorSubToolKey = keyof typeof Locales.subTools.frequencySelector.subTools;

type FrequencySelectorProps = {
    subToolColor?: string;
    subtoolKey: FrequencySelectorSubToolKey;
    stepName: string;
    interval: number;
    timeUnit: SubToolTemplateConfigurationFrequencyRange;
    frequencyType: SubToolsEventTriggers;
    subToolsEventTriggers: Array<SubToolsEventTriggers>;
    onConfigChange: (
        name: string,
        interval: number,
        timeUnit: SubToolTemplateConfigurationFrequencyRange,
        frequencyType: SubToolsEventTriggers
    ) => void;
};

export const FrequencySelector = ({
    subToolColor,
    subtoolKey,
    stepName,
    interval,
    timeUnit,
    subToolsEventTriggers,
    frequencyType,
    onConfigChange
}: FrequencySelectorProps) => {
    const {
        enums,
        subTools: { frequencySelector }
    } = useTranslator();
    const subToolsText = frequencySelector.subTools[subtoolKey];

    const handleChangeStepName: ChangeEventHandler<HTMLInputElement> = (event) => {
        onConfigChange(event.target.value, interval, timeUnit, frequencyType);
    };

    const handleChangeInterval: ChangeEventHandler<HTMLInputElement> = (event) => {
        onConfigChange(stepName, Number(event.target.value), timeUnit, frequencyType);
    };

    const handleChangeTimeUnit = (timeUnit: SubToolTemplateConfigurationFrequencyRange) => {
        onConfigChange(stepName, interval, timeUnit, frequencyType);
    };

    const handleChangeFrequencyType = (frequencyType: SubToolsEventTriggers) => {
        onConfigChange(stepName, interval, timeUnit, frequencyType);
    };

    const frequencyRangeOptions = useMemo(() => {
        return Object.keys(SubToolTemplateConfigurationFrequencyRange)
            .filter(
                (key) =>
                    typeof SubToolTemplateConfigurationFrequencyRange[key] === 'number' &&
                    SubToolTemplateConfigurationFrequencyRange[key] !==
                        SubToolTemplateConfigurationFrequencyRange.Seconds
            )
            .map((value) => {
                const name = GetTranslatedEnumName(value, enums.subToolTemplateConfigurationFrequencyRange);

                return {
                    id: SubToolTemplateConfigurationFrequencyRange[value],
                    label: name
                } as SelectData;
            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const eventTriggerOptions = useMemo(() => {
        return subToolsEventTriggers.map((value) => {
            const name = GetTranslatedEnumName(SubToolsEventTriggers[value], subToolsText.triggersLabels);

            return {
                id: value,
                label: name
            } as SelectData;
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subToolsEventTriggers]);

    return (
        <Card type='group' borderColor={subToolColor}>
            <CardHeader title={String(frequencySelector.title)}></CardHeader>
            <CardBody>
                <Content>
                    <InputFormGroup
                        name='stepName'
                        inputSize='small'
                        type='text'
                        autoComplete='off'
                        label={String(frequencySelector.labels.templateName)}
                        value={stepName}
                        onChange={handleChangeStepName}
                    />

                    <Label htmlFor='sendingPeriod'>
                        {String(frequencySelector.subtitle)}
                        <InfoTooltip>
                            <TooltipContentStyled>{String(subToolsText.tooltipContent)}</TooltipContentStyled>
                        </InfoTooltip>
                    </Label>

                    <PeriodContentStyled>
                        <Label>{String(frequencySelector.send)}</Label>

                        <InputFormGroup
                            name='sendingPeriod'
                            inputSize='small'
                            type='number'
                            value={interval || 1}
                            onChange={handleChangeInterval}
                        />

                        <SelectFormGroup
                            name='frequencyRange'
                            options={frequencyRangeOptions}
                            defaultValue={timeUnit}
                            onOptionChange={({ id }) =>
                                handleChangeTimeUnit(id as SubToolTemplateConfigurationFrequencyRange)
                            }
                        />

                        {isGreaterThanOne(subToolsEventTriggers.length) ? (
                            <SelectFormGroup
                                name='frequencyEventTrigger'
                                options={eventTriggerOptions}
                                defaultValue={frequencyType}
                                onOptionChange={({ id }) => handleChangeFrequencyType(id as SubToolsEventTriggers)}
                            />
                        ) : (
                            <Label>
                                {GetTranslatedEnumName(
                                    SubToolsEventTriggers[subToolsEventTriggers[0]],
                                    subToolsText.triggersLabels
                                )}
                            </Label>
                        )}
                    </PeriodContentStyled>
                </Content>
            </CardBody>
        </Card>
    );
};
