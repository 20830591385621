import { rgba } from 'polished';
import { SubToolIdEnum } from '@models';
import { fontSizes, fontWeights, fonts, lineHeights, radii, space } from './tokens';

const BOX_SHADOW_ALPHA_VALUE = 0.15;
const DISABLED_ALPHA_VALUE = 0.8;

const colors = {
    lightBlack: '#2A334A',
    white: '#FFFFFF',
    gallery: '#EEEEEE',
    lightGray: '#80868C',
    darkGray: '#333333',
    gray: '#666666',
    philippineSilver: '#B4B4b4',
    geminiGray: '#707070',
    grayPurple: '#FBFAFF',
    darkenedGrayPurple: '#F8F2FF',
    lightPurple: '#EDDEFF',
    enlightenedPurple: '#712C86',
    purple: '#9337B2',
    darkenedPurple: '#661174',
    darkPurple: '#4A1C59',
    yellow: '#FFC026',
    greenLeaf: '#49860B',
    green: '#00B83B',
    paleCyan: '#A3FFC5',
    darkGreen: '#69A82A',
    pink: '#FF0766',
    lightPink: '#FF8EB9',
    blue: '#1C4CEB',
    lightBlue: '#00C1DC',
    royalBlue: '#3975EA', // Facebook
    redOrange: '#FF3B30',
    sangria: '#941010',

    // This section corresponds to Enviou 2023 style guide
    eminencePurple: '#79318F',
    oldGold: '#D4BA11',
    pennRed: '#941010',
    seaSalt: '#FAFAFA',
    darkMossGreen: '#366506',

    deepCerise: '#C42783',
    licorice: '#2F384C'
} as const;

export const subToolColors: { [key in Uncapitalize<keyof typeof SubToolIdEnum>]: string } = {
    navigationTrigger: '#5D4AA1',
    repurchase: '#FC8326',
    customTrigger: '#4AA8B1',
    cartRecovery: '#70AD47',
    bankSlipReminder: '#FF577F',
    emails: '#1CA5DF'
} as const;

export const pluginsColors = {
    birthday: colors.eminencePurple,
    meudimdim: '#1D675B',
    whatsapp: subToolColors.cartRecovery
} as const;

export const alertColors = {
    default: colors.purple,
    info: colors.blue,
    warning: colors.oldGold,
    error: colors.sangria,
    success: colors.darkMossGreen
} as const;

export type ThemeColor = keyof typeof colors;
export type SubToolColors = keyof typeof subToolColors;
export type PluginsColors = keyof typeof pluginsColors;
export type AlertColors = keyof typeof alertColors;

export const automationToolFunnelChartColors = {
    0: '#1D0B22',
    1: '#391643',
    2: '#552164',
    3: '#712C85',
    4: '#8D569D',
    5: '#AA80B6',
    6: '#CEA0DB'
};

export const emailMarketingFunnelChartColors = {
    0: '#000000',
    1: '#0B1A1D',
    2: '#102E33',
    3: '#1A4B54',
    4: '#2E8594',
    5: '#42BED4',
    6: '#67CFE2'
};

const containerInRem = {
    maxWidth: 128,
    padding: 2.4
} as const;

export const theme = {
    colors,
    subToolColors,
    pluginsColors,
    alertColors,
    funnelChart: {
        automationToolFunnelChartColors,
        emailMarketingFunnelChartColors
    },
    container: {
        maxWidth: `${containerInRem.maxWidth}rem`,
        paddingX: `${containerInRem.padding}rem`,
        rem: containerInRem
    },
    theme: 'light',
    fontSizes,
    fontWeights,
    fonts,
    lineHeights,
    radii,
    space,
    default: {
        color: colors.lightBlack,
        backgroundColor: colors.white,
        borderColor: colors.lightBlack,
        dangerColor: colors.redOrange,
        boxShadow: {
            alphaValue: BOX_SHADOW_ALPHA_VALUE,
            default: `0 0.3rem 1rem ${rgba(colors.lightBlack, BOX_SHADOW_ALPHA_VALUE)}`,
            secondary: `1px 1px 6px ${rgba(colors.lightBlack, BOX_SHADOW_ALPHA_VALUE)}`
        },
        _selection: {
            color: colors.white,
            backgroundColor: colors.purple
        },
        anchor: {
            color: colors.purple
        },
        input: {
            color: colors.lightBlack,
            backgroundColor: colors.white,
            borderColor: colors.purple,
            disabledBorderColor: colors.gray,
            outlineColor: colors.darkPurple
        },
        label: {
            disabled: `${rgba(colors.lightGray, DISABLED_ALPHA_VALUE)}`
        },
        mark: {
            color: colors.lightBlack,
            backgroundColor: colors.lightPurple,
            _selection: {
                color: colors.white,
                backgroundColor: colors.darkPurple
            }
        },
        footer: {
            backgroundColor: colors.purple,
            secondBackgroundColor: colors.darkPurple,
            color: colors.white
        },
        shimmer: {
            primaryColor: '#eff1f3',
            secondColorColor: '#e2e2e2',
            thirdColor: '#eff1f3'
        },
        whatsappComponent: {
            iconColor: colors.white
        }
    }
} as const;

export default theme;
