import { MutableRefObject, useMemo, useState } from 'react';
import debounce from 'lodash/debounce';
import * as Yup from 'yup';
import {
    PhoneFramePreview,
    SmsTemplatePreview,
    ModelsTemplateEditor,
    TextareaFormGroup,
    InputFormGroup
} from '@components';
import { VariableParentKey, useTemplate, useTranslator } from '@hooks';
import { CustomizationVariable, SendingTechnologyTypeEnum, SubTool } from '@models';
import { useAlert, useAuth } from '@providers';
import { captureException } from '@sentry/nextjs';
import { FormHandles } from '@unform/core';
import {
    getOnlyNumbers,
    isDevelopment,
    MAX_LENGTH_TELEPHONE,
    MAX_SMS_MESSAGE_SIZE,
    MIN_LENGTH_TELEPHONE
} from '@utils';
import { HandleSendTestSMS } from '~/services/subToolService';
import { InputGroupStyled } from './styles';

type SMSTemplateEditorProps = {
    subTool?: SubTool;
    formRef: MutableRefObject<FormHandles>;
    initialValue: string;
    customizationVariables?: CustomizationVariable[];
    isDefaultTemplate?: boolean;
    subToolKeyIdentifier: VariableParentKey;
    isReduced?: boolean;
    onChangeEnableTechnology?: (checked: boolean, sendingTechnologyType: SendingTechnologyTypeEnum) => void;
    enableTechnology?: boolean;
    subToolColor?: string;
};

const WAIT_TIME_IN_MILLISECONDS_TO_EXECUTE_ON_CHANGE = 600;
const CELL_PHONE_FIELD_NAME = 'CellPhone';
const INPUT_NAME = 'smsMessage';

export const SMSTemplateEditor = ({
    formRef,
    initialValue,
    customizationVariables = [],
    subToolKeyIdentifier,
    onChangeEnableTechnology,
    subToolColor,
    enableTechnology = true,
    isReduced = false
}: SMSTemplateEditorProps) => {
    const [message, setMessage] = useState(initialValue ?? '');
    const { generateTemplatePreviewBySendingTechnology } = useTemplate(subToolKeyIdentifier);
    const { user } = useAuth();
    const { error, success } = useAlert();
    const testInputName = `${INPUT_NAME}${CELL_PHONE_FIELD_NAME}`;
    const sendingTechnology = SendingTechnologyTypeEnum.SMS;

    const variables = useMemo(() => {
        let variables = [];
        customizationVariables?.forEach((item) => {
            variables = [...variables, ...item.variables.map((variable) => variable?.toLowerCase())];
        });

        return variables.filter(Boolean);
    }, [customizationVariables]);

    const [formattedMessage, setFormattedMessage] = useState(() =>
        generateTemplatePreviewBySendingTechnology({
            user,
            baseHtml: initialValue,
            sendingTechnology: sendingTechnology,
            allowedVariables: variables
        })
    );

    const [isCheckSecurityModalOpen, setIsCheckSecurityModalOpen] = useState(false);

    const {
        dialogs: { errors, successes },
        subTools: {
            modelsTemplateEditor: { errorMessages, sendTestMessageButtonText, describedInputPlaceholder },
            smsTemplateEditor: { title, subtitle, placeholder }
        }
    } = useTranslator();

    const openTestEmailModal = () => {
        setIsCheckSecurityModalOpen(true);
    };

    const closeCheckSecurityModal = async (recaptcha?: string) => {
        try {
            setIsCheckSecurityModalOpen(false);
            if (recaptcha) {
                const payload = {
                    to: getOnlyNumbers(formRef.current?.getFieldValue(testInputName)),
                    text: formattedMessage,
                    recaptchaToken: recaptcha
                };

                await HandleSendTestSMS(payload)
                    .then((response) => {
                        if (!response?.data?.success) {
                            error(
                                String(errors.failedToSendTestSMS.title),
                                String(errors.failedToSendTestSMS.description)
                            );
                        } else {
                            success(String(successes.successfullySendingTestSMS));
                        }
                    })
                    .catch((exception) => {
                        error(String(errors.failedToSendTestSMS.title), String(errors.failedToSendTestSMS.description));
                        captureException(exception);
                    });
            }
        } catch (exception) {
            captureException(exception);
            error(String(errors.failedToSendTestSMS.title), String(errors.failedToSendTestSMS.description));
        }
    };

    const handleTextChange = debounce((text: string) => {
        setMessage(text);

        const preview = generateTemplatePreviewBySendingTechnology({
            user,
            baseHtml: text,
            sendingTechnology: sendingTechnology,
            allowedVariables: variables
        });

        setFormattedMessage(preview);
    }, WAIT_TIME_IN_MILLISECONDS_TO_EXECUTE_ON_CHANGE);

    const handleClick = async () => {
        try {
            const schema = Yup.object().shape({
                [INPUT_NAME]: Yup.string().required(errorMessages.emptySms).nullable(),

                [testInputName]: Yup.string()
                    .required(errorMessages.senderCellPhone.required)
                    .nullable()
                    .min(MIN_LENGTH_TELEPHONE, errorMessages.senderCellPhone.minLength)
                    .max(MAX_LENGTH_TELEPHONE, errorMessages.senderCellPhone.maxLength)
            });

            formRef.current.setFieldError(INPUT_NAME, null);
            formRef.current.setFieldError(testInputName, null);

            const message = formRef.current.getFieldValue(INPUT_NAME);
            const cellPhoneInputValue = formRef.current.getFieldValue(testInputName);
            await schema.validate(
                { [INPUT_NAME]: message, [testInputName]: cellPhoneInputValue },
                { abortEarly: false }
            );
            openTestEmailModal();
        } catch (exception) {
            if (exception instanceof Yup.ValidationError) {
                const errorMessages = {};

                exception.inner.forEach((error) => (errorMessages[error.path] = error.message));

                const currentErrors = formRef.current.getErrors();
                formRef.current.setErrors({ ...currentErrors, ...errorMessages });

                isDevelopment() && console.log(errorMessages);
            } else {
                captureException(exception);
                error(String(errors.failedToSendTestSMS.validationError), (exception as Error)?.message);
            }
        }
    };

    const onChangeEnabled = (checked: boolean) => {
        onChangeEnableTechnology(checked, sendingTechnology);
    };

    return (
        <ModelsTemplateEditor
            key={`${INPUT_NAME}${CELL_PHONE_FIELD_NAME}EDITOR`}
            customizationVariables={customizationVariables}
            hasCustomizationVariables={variables.length > 0}
            subToolKeyIdentifier={subToolKeyIdentifier}
            subToolColor={subToolColor}
            subtitle={String(subtitle)}
            title={String(title)}
            closeCheckSecurityModal={closeCheckSecurityModal}
            onChangeEnableTechnology={onChangeEnabled}
            enableTechnology={enableTechnology}
            isCheckSecurityModalOpen={isCheckSecurityModalOpen}
            sendingTechnology={sendingTechnology}
            formComponents={
                <>
                    <TextareaFormGroup
                        name={INPUT_NAME}
                        cols={30}
                        rows={6}
                        maxLength={MAX_SMS_MESSAGE_SIZE}
                        onChange={(event) => handleTextChange(event.target.value)}
                        defaultValue={message}
                        placeholder={String(placeholder)}
                    />
                    <InputFormGroup
                        type='text'
                        id='smsPreview'
                        name='smsPreview'
                        value={formattedMessage}
                        containerClassName='hidden'
                    />
                </>
            }
            inputTestComponent={
                <InputGroupStyled
                    inputType='tel'
                    inputMask='phone'
                    inputName={testInputName}
                    buttonText={String(sendTestMessageButtonText)}
                    inputPlaceHolder={String(describedInputPlaceholder)}
                    onClick={handleClick}
                />
            }
            modelPreview={
                <PhoneFramePreview isReduced={isReduced}>
                    <SmsTemplatePreview message={formattedMessage} />
                </PhoneFramePreview>
            }
        />
    );
};
