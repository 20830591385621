import { produce } from 'immer';
import _lowerFirst from 'lodash/lowerFirst';
import { GetServerSidePropsContext } from 'next';
import {
    getAllTemplateVariables,
    getVariable,
    makeVariable,
    normalizeVariable,
    replaceTemplateVariableName,
    TemplateVariable,
    translateAllTemplateVariable,
    translateVariable,
    translator,
    VariableParentKey
} from '@hooks';
import { MessageSequenceStep, SubToolId, SubToolIdEnum, ThemeTemplate } from '@models';
import { escapeRegex } from '@utils';

type NormalizeBaseThemeVariablesProps = {
    baseHTML?: string;
    productHtml?: string;
    reviewHtml?: string;
};

type LocaleData = { defaultLocale: string; locale: string; locales: string[] };

export const translatedStepTemplates = (
    context: GetServerSidePropsContext | LocaleData,
    messageSequenceStep: MessageSequenceStep,
    subToolId: SubToolId
) => {
    if (!messageSequenceStep || !messageSequenceStep.templates) {
        return messageSequenceStep;
    }

    const { defaultLocale, locale, locales } = context;

    const variables = translator({
        locale,
        locales,
        defaultLocale
    }).variables;

    const identifier = _lowerFirst(SubToolIdEnum[subToolId]) as VariableParentKey;

    messageSequenceStep.templates = produce(messageSequenceStep.templates, (draft) => {
        draft.forEach((template) => {
            template.subject = translateAllTemplateVariable(template.subject, variables, identifier);
            template.baseContent = translateAllTemplateVariable(template.baseContent, variables, identifier);
            template.productContent = translateAllTemplateVariable(template.productContent, variables, identifier);
            template.reviewContent = translateAllTemplateVariable(template.reviewContent, variables, identifier);
        });
    });

    return messageSequenceStep;
};

export const normalizeBaseThemeVariables = ({
    baseHTML,
    productHtml,
    reviewHtml
}: NormalizeBaseThemeVariablesProps) => {
    const variables = [
        { name: '[LOJA:TELEFONE]', newName: '[LOJA.TELEFONE]' },
        { name: '[LOJA:NOMELOJA]', newName: '[LOJA.NOME]' },
        { name: '[LOJA:EMAIL]', newName: '[LOJA.EMAIL]' },
        { name: '[LOJA:LOJA]', newName: '[LOJA.URL]' },
        { name: '[LOJA:LOGOTIPO]', newName: '[LOJA.LOGOTIPO]' },
        { name: '[LOJA.LOGO]', newName: '[LOJA.LOGOTIPO]' },
        { name: '[LOJA:LOGO]', newName: '[LOJA.LOGOTIPO]' },
        { name: '[LOJA:URLLOJA]', newName: '[LOJA.URL]' },
        { name: '[LOJA:CNPJCNPJ]', newName: '[LOJA.CPF_CNPJ]' },
        { name: '[CARRINHO.PRODUTO.REVIEW_1]', newName: '[CARRINHO.PRODUTO.REVIEW]' },
        { name: '[BOLETO.PRODUTO.TOTALCOMPRA]', newName: '[BOLETO.PRODUTO.TOTAL_COMPRA]' },
        { name: '[BOLETO.CODIGOBARRAS]', newName: '[BOLETO.CODIGO_BARRAS]' },
        { name: '[LOJA:Facebook]', newName: '[LOJA.FACEBOOK]' },
        { name: '[LOJA:Twitter]', newName: '[LOJA.TWITTER]' },
        { name: '[LOJA:Instagram]', newName: '[LOJA.INSTAGRAM]' },
        { name: '[LOJA:Pinterest]', newName: '[LOJA.PINTEREST]' },
        { name: '[LOJA:GooglePlus]', newName: '[LOJA.GOOGLEPLUS]' },
        { name: '[LOJA:Youtube]', newName: '[LOJA.YOUTUBE]' },
        { name: '[LOJA:PagSeguro]', newName: '[LOJA.PAGSEGURO]' },
        { name: '[LOJA:MercadoPago]', newName: '[LOJA.MERCADOPAGO]' },
        { name: '[LOJA:Bcash]', newName: '[LOJA.BCASH]' },
        { name: '[LOJA:PayPal]', newName: '[LOJA.PAYPAL]' },
        { name: '[LOJA:Pagarme]', newName: '[LOJA.PAGARME]' },
        { name: '[LOJA:Bandeiras]', newName: '[LOJA.BANDEIRAS]' },
        { name: '[LOJA:Deposito]', newName: '[LOJA.DEPOSITO]' },
        { name: '[LOJA:Boleto]', newName: '[LOJA.BOLETO]' },
        { name: '[LOJA:Koin]', newName: '[LOJA.KOIN]' },
        { name: '[LOJA:Entrega]', newName: '[LOJA.PAGAR_NA_ENTREGA]' }
    ];

    for (const variable of variables) {
        const variableName = normalizeVariable(variable.name);
        baseHTML = replaceTemplateVariableName(baseHTML, variableName, variable.newName);
        productHtml = replaceTemplateVariableName(productHtml, variableName, variable.newName);
        reviewHtml = replaceTemplateVariableName(reviewHtml, variableName, variable.newName);
    }

    return { baseHTML, productHtml, reviewHtml };
};

export const removeBaseThemeVariables = (theme: ThemeTemplate) => {
    const variables = [];

    for (const variable of variables) {
        theme.htmlBase = theme.htmlBase.replaceAll(variable, '');
    }

    return theme;
};

const getRegexVariableName = (variable: TemplateVariable) => {
    let regexVariableName = null;

    if (variable.isRegex) {
        for (const pattern of variable?.variables) {
            const variableRegex = new RegExp(pattern, 'i');
            const variableValues = makeVariable(variable.key)?.match(variableRegex) ?? [];

            if (variableValues.length > 0) {
                regexVariableName = normalizeVariable(variableValues[0]);
                break;
            }
        }
    }

    return regexVariableName;
};

export const replaceParagraphTagWithDiv = (html: string, subToolId: SubToolIdEnum, localeData: LocaleData) => {
    const variables = [
        'cart.products',
        'products.top_selling.general.x',
        'products.top_selling.category.x',
        'cart.product.review',
        'cart.product.recommendations.x',
        'navigated.products.latest.x',
        'navigated.products.highest_value.x',
        'bankslip.products'
    ];

    const templateVariables = getAllTemplateVariables(html);
    const identifier = _lowerFirst(SubToolIdEnum[subToolId]) as VariableParentKey;

    const { locale, defaultLocale, locales } = localeData;

    const localVariables = translator({
        locale,
        locales,
        defaultLocale
    }).variables;

    templateVariables.forEach((variableName) => {
        variableName = normalizeVariable(variableName);
        const variable = getVariable(variableName, identifier);
        const regexVariableName = getRegexVariableName(variable);

        const tempVariable = variable.isRegex && regexVariableName ? regexVariableName : variableName;
        const regex = new RegExp(`<p>.*\\[${escapeRegex(tempVariable)}\\].*</p>`, 'i');

        if (regex.test(html)) {
            const translatedVariable = translateVariable(variable, localVariables);

            const isValid =
                (!variable.isRegex && variables.includes(variable.key) && translatedVariable) ||
                (variable.isRegex && variables.includes(variable.originalKey));

            if (isValid) {
                const replaceRegex = new RegExp(`<p>(.*\\[${escapeRegex(tempVariable)}\\].*)</p>`, 'i');
                html = html.replace(replaceRegex, '<div>$1</div>');
            }
        }
    });

    return html;
};
