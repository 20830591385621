import { ReactNode } from 'react';
import { useExpandableGroup } from '@providers';
import { ExpandableGroupStyled, ExpandableGroupContentStyled } from './styles';

type CustomerItemDetailsProps = { children: ReactNode };

export const CustomerItemDetails = ({ children }: CustomerItemDetailsProps) => {
    const { expanded, triggerId, contentId } = useExpandableGroup();
    return (
        <ExpandableGroupStyled isExpanded={expanded} role='region' aria-labelledby={triggerId} id={contentId}>
            <ExpandableGroupContentStyled>
                <div>{children}</div>
            </ExpandableGroupContentStyled>
        </ExpandableGroupStyled>
    );
};
