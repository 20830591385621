import styled from 'styled-components';

export const ProductContentStyled = styled.div`
    width: 100%;
    max-width: 100.4rem;
`;

export const ProductsStyled = styled.div`
    .keen-slider__left-arrow,
    .keen-slider__right-arrow {
        width: 1.4rem;
    }
`;

export const ProductStyled = styled.div`
    a {
        transition: all 0.15s ease-out;
        display: flex;
        border: 1px solid ${({ theme }) => theme.colors.lightPurple};
        border-radius: ${({ theme }) => theme.radii.md};
        position: relative;
        margin: 1.2rem 0;

        &:hover {
            box-shadow: ${({ theme }) => theme.default.boxShadow.secondary};
        }

        .image {
            border-radius: ${({ theme }) => theme.radii.md};
            display: flex;
            overflow: hidden;
            background: ${({ theme }) => theme.colors.white};

            img {
                width: 9.2rem;
                height: 9.2rem;
                object-fit: cover;
                padding: 0.4rem;
            }

            span {
                position: absolute;
                top: 0;
                right: 0;
            }
        }

        .content {
            padding: 0.8rem 1.2rem 0.8rem 0.8rem;
            display: flex;
            flex-direction: column;
            margin: auto 0;
            gap: 1rem;
            justify-content: space-between;
            flex: 1;

            div:first-child {
                display: flex;
                flex-direction: column;
                gap: 0.2rem;

                strong,
                p {
                    line-height: 1;
                    max-width: 24rem;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }
            }

            div:last-child {
                display: flex;
                gap: 0.2rem;
                justify-content: space-between;

                p {
                    color: ${({ theme }) => theme.colors.licorice};
                }
            }
        }
    }
`;
