import { useRef, useState } from 'react';
import { useRouter } from 'next/router';
import * as yup from 'yup';
import { Button } from '@components';
import { ContactConfiguration } from '@containers';
import { ConfirmCancellationDialog } from '@dialogs';
import { useSubToolPage, useTranslator } from '@hooks';
import {
    ContactConfigurationStatusEnum,
    SendingTechnologyTypeEnum,
    SubToolConfiguration as SubToolConfigurationModel
} from '@models';
import { useAlert } from '@providers';
import { captureException } from '@sentry/nextjs';
import type { FormHandles, SubmitHandler } from '@unform/core';
import { Form } from '@unform/web';
import { translateRoute } from '@utils';
import { routesName } from '~/locales/route';
import {
    CreateOrUpdateSubToolConfigurationAsync,
    UpdateCartRecoveryConfigurationPayload
} from '~/services/cartRecoveryApiService';
import { EnviouApiResponseError } from '~/services/errors/enviouApiResponseError';
import { PageTitle } from '~/styles/defaultComponentStyles';
import { SubToolConfigurationButtonGroupStyled, SubToolConfigurationStyled } from './styles';
import { SubToolConfigurationFormData, hasChangesInForm, useValidation } from './validation';

type CartRecoveryConfigurationTemplateProps = {
    subToolConfiguration?: SubToolConfigurationModel;
};

export const CartRecoveryConfigurationTemplate = ({ subToolConfiguration }: CartRecoveryConfigurationTemplateProps) => {
    const {
        buttons,
        subTools,
        api: { statusError },
        dialogs: { success: successMessage }
    } = useTranslator();
    const formRef = useRef<FormHandles>();
    const subToolConfigurationRef = useRef<SubToolConfigurationModel>(subToolConfiguration);
    const [isLoading, setIsLoading] = useState(false);
    const [showCancellationModal, setShowCancellationModal] = useState(false);
    const { getSubToolPageData } = useSubToolPage();
    const { translatedSubToolName } = getSubToolPageData(subToolConfiguration.subTool.id);
    const { success, error } = useAlert();
    const { subToolConfigurationSchema } = useValidation();
    const router = useRouter();

    const redirectToSubToolPage = async () => {
        const routePath = routesName.tool.automationTool;

        const { locale } = router;

        const translatedRoutePath = translateRoute(routePath, locale);

        router.push({ pathname: routePath }, translatedRoutePath);
    };

    const handleSubmit: SubmitHandler<SubToolConfigurationFormData> = async (formData) => {
        try {
            setIsLoading(true);
            formRef.current.setErrors({});

            await subToolConfigurationSchema.validate(formData, { abortEarly: false });

            const payload: UpdateCartRecoveryConfigurationPayload = {
                id: subToolConfiguration.id,
                subToolId: subToolConfiguration.subTool.id,
                status: subToolConfiguration.status,
                contactConfigurations: formData.contactConfigurations.map((contact) => ({
                    id: contact?.contactConfigurationId ?? undefined,
                    subToolConfigurationId: subToolConfiguration.id,
                    senderName: contact.senderName,
                    senderContact: contact.senderContact,
                    contactAnswer: contact.contactAnswer,
                    sendingTechnology: contact?.sendingTechnology,
                    status: ContactConfigurationStatusEnum.Active
                }))
            };

            await CreateOrUpdateSubToolConfigurationAsync(payload)
                .then((response) => {
                    setIsLoading(false);
                    subToolConfigurationRef.current = response.data;
                    success(String(successMessage.savedSubToolConfiguration));
                    return redirectToSubToolPage();
                })
                .catch((_error) => {
                    setIsLoading(false);
                    captureException(_error);

                    const errorMessage =
                        _error instanceof EnviouApiResponseError
                            ? _error.getFirstError('updateSubToolErrorStatus').message
                            : String(statusError.systemErrorStatus.internalError);

                    error(errorMessage);
                });
        } catch (exception) {
            if (exception instanceof yup.ValidationError) {
                const errorMessages = {};

                exception.inner.forEach((error) => (errorMessages[error.path] = error.message));
                formRef.current.setErrors(errorMessages);
            } else {
                captureException(exception);
            }
            setIsLoading(false);
        }
    };

    const handleCancelButton = () => {
        const formData = formRef.current.getData() as SubToolConfigurationFormData;
        if (hasChangesInForm(formData, subToolConfigurationRef.current)) {
            setShowCancellationModal(true);

            return;
        }
        redirectToSubToolPage();
    };

    return (
        <Form ref={formRef} onSubmit={handleSubmit} initialData={subToolConfiguration}>
            <SubToolConfigurationStyled>
                <PageTitle>{translatedSubToolName}</PageTitle>
                <ContactConfiguration
                    allowedSendingTechnologies={[SendingTechnologyTypeEnum.Email]}
                    contacts={subToolConfiguration.contactConfigurations}
                    subToolColor='cartRecovery'
                    showIsActiveCheckbox={false}
                />
                <SubToolConfigurationButtonGroupStyled>
                    <ConfirmCancellationDialog
                        options={{ open: showCancellationModal, onOpenChange: setShowCancellationModal }}
                        triggerElement={
                            <Button color='red' buttonSize='small' isOutlined onClick={handleCancelButton}>
                                {String(buttons.cancel)}
                            </Button>
                        }
                        title={String(subTools.subToolConfiguration.messageOnExit)}
                        onYesClick={redirectToSubToolPage}
                        onToBackClick={() => setShowCancellationModal(false)}
                    />

                    <Button color='purple' buttonSize='small' type='submit' inLoading={isLoading}>
                        {String(buttons.save)}
                    </Button>
                </SubToolConfigurationButtonGroupStyled>
            </SubToolConfigurationStyled>
        </Form>
    );
};
