import styled from 'styled-components';
import { ExpandableGroup } from '~/components/elements/expandableGroup';

export const ExpandableGroupStyled = styled(ExpandableGroup)`
    & > div {
        cursor: default;
        padding: 0 2.4rem;
    }
`;

export const ExpandableGroupContentStyled = styled.div`
    border-top: 1px solid ${({ theme }) => theme.colors.lightPurple};
    padding: 2rem 0 2rem 11rem;

    & > div {
        display: flex;
        flex-direction: column;
        gap: 1.6rem;
    }
`;
