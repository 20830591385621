import qs from 'qs';
import axiosClient from './apiService';

const facebookRoute = '/sessions/facebook';

export const GetFacebookAuthenticationUrl = async (
    returnUrl: string,
    scopes: string[] | null = null,
    isIntegration = false,
    signal: AbortSignal = null
) => {
    return axiosClient.get<string>(`${facebookRoute}/login`, {
        params: { returnUrl, scopes, isIntegration, redirect: false },
        paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat', skipNulls: true }),
        signal,
        maxRedirects: 0
    });
};
