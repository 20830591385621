import _get from 'lodash/get';
import _has from 'lodash/has';
import localVariables from '~/locales/locales.json';
import { makeVariable } from './variableFunctions';

const localVariableToDefineVariableValueType =
    localVariables.variables.navigationTrigger['navigated.products.latest.x'];

export type LocalVariables = Pick<
    typeof localVariables.variables,
    'bankSlipReminder' | 'cartRecovery' | 'customTrigger' | 'navigationTrigger' | 'repurchase' | 'shared'
>;
export type VariableParentKey = keyof LocalVariables;

export type WithOptional<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;
type VariableValue = WithOptional<typeof localVariableToDefineVariableValueType, 'regex' | 'paramsDescription'>;

export type TemplateVariable = {
    parentKey?: VariableParentKey;
    key: string;
    originalKey?: string;
    variables: Array<string>;
    isRegex?: boolean;
};

export type AddVariableFieldName = keyof Omit<VariableValue, 'description'>;

type AddVariableProps = {
    key: string;
    value: VariableValue;
    parentKey: VariableParentKey;
    fieldName: AddVariableFieldName;
};

type GetLocalVariablesProps = {
    parentKey?: VariableParentKey;
    key?: Array<string>;
    isRegex?: boolean;
};

let variables: TemplateVariable[] = [];

const isVariables = variables.length > 0;

const addLocalVariable = ({ key, value, parentKey, fieldName }: AddVariableProps) => {
    if (value && _has(value, fieldName)) {
        variables.push({
            parentKey,
            key,
            variables: Object.values(_get(value, fieldName, [])),
            isRegex: fieldName === 'regex'
        });
    }
};

const isRegexVariableMatch = (regexVariables: Array<string>, variableName: string) => {
    return regexVariables.find((regexVariable) => {
        const variable = makeVariable(variableName);
        const regex = new RegExp(regexVariable, 'gi');

        const match = variable.match(regex) ?? [];
        return match.length > 0;
    });
};

const setKeyInRegexVariable = (variable: TemplateVariable, newKey: string) => {
    if (variable?.key) {
        variable.originalKey = variable.key;
        variable.key = newKey;
    }
};

export const generateLocalVariables = () => {
    variables = [];

    Object.entries(localVariables.variables).forEach(([parentKey, parentValue]) => {
        Object.entries(parentValue).forEach(([key, value]) => {
            const variableParentKey = parentKey as VariableParentKey;

            addLocalVariable({ key, value, parentKey: variableParentKey, fieldName: 'name' });
            addLocalVariable({ key, value, parentKey: variableParentKey, fieldName: 'regex' });
        });
    });

    return variables;
};

export const getAllVariables = ({ parentKey = null, isRegex = null, key = null }: GetLocalVariablesProps) => {
    if (!isVariables) {
        generateLocalVariables();
    }

    return variables.filter(
        (variable) =>
            (key === null || key.includes(variable.key)) &&
            (parentKey === null || variable.parentKey === parentKey || variable.parentKey === 'shared') &&
            (isRegex === null || variable.isRegex === isRegex)
    );
};

export const getRegexTypeVariableByName = (name: string, parentKey: VariableParentKey) => {
    const variable = {
        ...getAllVariables({ parentKey, isRegex: true }).find(({ variables: regexVariables }) =>
            isRegexVariableMatch(regexVariables, name)
        )
    };

    setKeyInRegexVariable(variable, name);

    return variable;
};

export const getVariableByName = (name: string, parentKey: VariableParentKey) => {
    return getAllVariables({ parentKey, isRegex: false }).find(({ variables: variableList }) =>
        variableList.includes(name?.toLowerCase())
    );
};

export const getVariable = (variableName: string, parentKey: VariableParentKey) => {
    let variable = getVariableByName(variableName, parentKey);

    if (!variable) {
        variable = getRegexTypeVariableByName(variableName, parentKey);
    }

    return variable;
};

export const getVariableValue = (allLocalVariables: LocalVariables, variable: TemplateVariable) => {
    if (!variable) {
        return null;
    }

    const { parentKey, key } = variable;

    return allLocalVariables[parentKey][key] as VariableValue;
};
