/* eslint-disable @next/next/no-img-element */
import _random from 'lodash/random';
import { Text, Slide, Carousel } from '@components';
import { useNumberFunctions, useTranslator } from '@hooks';
import { ShoppingCartItem, SubToolIdEnum } from '@models';
import { NO_CATEGORY } from '@utils';
import { ProductContentStyled, ProductsStyled, ProductStyled } from './styles';

export type ProductContentProps = { products: ShoppingCartItem[]; subToolId: SubToolIdEnum };
type ProductProps = { product: ShoppingCartItem };

const Product = ({ product }: ProductProps) => {
    const { formatCurrency } = useNumberFunctions();
    const {
        pages: {
            automationStatistics: {
                statisticsPerCustomerSection: { productContent }
            }
        }
    } = useTranslator();

    const category =
        !product.category || product.category === NO_CATEGORY ? String(productContent.noCategory) : product.category;
    return (
        <ProductStyled data-product-id={product.productIdentifier}>
            <a href={product.productUrl} target='_blank' rel='noopener noreferrer'>
                <div className='image'>
                    <img src={product.imageUrl} alt={product.description} />
                </div>
                <div className='content'>
                    <div>
                        <Text as='strong' size='sm' title={product.description}>
                            {product.description}
                        </Text>
                        <Text size='xxs' color='philippineSilver' title={category}>
                            {category}
                        </Text>
                    </div>
                    <div>
                        <Text size='xs'>
                            {product.quantity}{' '}
                            {String(product.quantity === 1 ? productContent.item : productContent.items)}
                        </Text>
                        <Text size='xs' as='strong' color='purple'>
                            {formatCurrency(product.unitPrice)}
                        </Text>
                    </div>
                </div>
            </a>
        </ProductStyled>
    );
};

export const ProductContent = ({ products }: ProductContentProps) => {
    const {
        pages: {
            automationStatistics: {
                statisticsPerCustomerSection: { productContent }
            }
        }
    } = useTranslator();

    if (!products || products.length === 0) {
        return <></>;
    }
    return (
        <ProductContentStyled>
            <Text as='strong' size='sm'>
                {String(productContent.title)}
            </Text>

            <ProductsStyled>
                <Carousel
                    settings={{
                        initial: 0,
                        loop: false,
                        mode: 'free',
                        slides: { origin: 'auto', perView: 2.75, spacing: 12 }
                    }}
                    name={`product-carroulser-${_random()}`}>
                    {products.map((item) => (
                        <Slide key={item.productIdentifier}>
                            <Product product={item} />
                        </Slide>
                    ))}
                </Carousel>
            </ProductsStyled>
        </ProductContentStyled>
    );
};
