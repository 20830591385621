import styled from 'styled-components';
import pattern from '~/assets/svg/background/whatsapp_pattern-cross.svg?url';

export const ContainerStyled = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    overflow-y: auto;
    width: 100%;
    background: #f0e7de url(${pattern});

    & > div {
        width: 100%;
        padding: 4rem 2.5rem;
        position: relative;
        top: -50%;
        transform: translateY(50%);
    }
`;

export const ConversationStyled = styled.div`
    display: flex;
    flex-direction: column;
    padding-bottom: 2rem;

    div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 95%;
        height: 100%;
        margin: auto;
        padding: 1.5rem;
        min-height: 95px;
        background: #dbfac6;
        border-radius: 10px 10px 10px 0;
        box-shadow: 1px 1px 6px #2a334a26;

        p {
            height: auto;
            overflow-wrap: break-word;
            word-wrap: break-word;
            word-break: break-word;
            text-align: left;
            width: 100%;
            color: black;
        }

        &:after {
            content: '';
            position: absolute;
            bottom: -15px;
            left: 0;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 16px 16px 0 0;
            border-color: #dbfac6 transparent transparent transparent;
            filter: drop-shadow(1px 3px 3px #2a334a26);
        }
    }
`;

export const MessageHeaderStyled = styled.p`
    font-weight: bold;
    font-size: 1.6rem;
`;

export const MessageBodyStyled = styled.p`
    font-weight: normal;
    font-size: 1.5rem;
`;

export const MessageFooterStyled = styled.p`
    font-weight: lighter;
    font-size: 1.4rem;
    margin-top: 0.5rem;
`;
