import { useCallback, useRef, useState } from 'react';
import _lowerFirst from 'lodash/lowerFirst';
import { ValidationError } from 'yup';
import { CreateStepTemplate } from '@components';
import { AbandonmentSettings } from '@containers';
import { useTranslator } from '@hooks';
import {
    CustomizationVariable,
    SubToolIdEnum,
    MessageSequenceStep,
    NavigationTriggerRule,
    NavigationTriggerRuleConfiguration,
    NavigationTriggerRuleConfigurationUpdate
} from '@models';
import { captureException } from '@sentry/nextjs';
import { FormHandles } from '@unform/core';
import { convertTreeToList, isDevelopment, scrollTo } from '@utils';
import { useAlert } from '~/providers/alertProvider';
import { EnviouApiResponseError } from '~/services/errors/enviouApiResponseError';
import { UpdateRuleConfigurationAsync } from '~/services/navigationTriggerApiService';
import { CreateRulesTree } from '~/services/navigationTriggerService';
import theme from '~/styles/theme';
import { useValidation } from './validation';

type NavigationTriggerTemplateProps = {
    smsTemplateVariables: Array<CustomizationVariable>;
    emailTemplateVariables: Array<CustomizationVariable>;
    whatsappTemplateVariables: Array<CustomizationVariable>;
    pushTemplateVariables: Array<CustomizationVariable>;
    messageSequenceStep?: MessageSequenceStep;
    messageSequenceStepId?: number;
    subToolPath: string;
    navigationTriggerRuleConfiguration: NavigationTriggerRuleConfiguration;
    subToolId: SubToolIdEnum;
    isWhatsAppBusiness: boolean;
};

export const NavigationTriggerTemplate = ({
    smsTemplateVariables,
    emailTemplateVariables,
    messageSequenceStep,
    whatsappTemplateVariables,
    pushTemplateVariables,
    navigationTriggerRuleConfiguration,
    subToolPath,
    subToolId,
    isWhatsAppBusiness
}: NavigationTriggerTemplateProps) => {
    const subToolColor = theme.subToolColors[_lowerFirst(SubToolIdEnum[subToolId])];
    const { subToolTemplateConfigurationSchema } = useValidation();
    const formRef = useRef<FormHandles>();
    const { error } = useAlert();
    const {
        dialogs: { errors }
    } = useTranslator();

    const [rulesTree, setRulesTree] = useState<Array<NavigationTriggerRule>>(() =>
        CreateRulesTree(navigationTriggerRuleConfiguration?.rules)
    );

    const updateNavigationTriggerConfiguration = useCallback(
        async (formData, interval): Promise<NavigationTriggerRuleConfiguration> => {
            try {
                await subToolTemplateConfigurationSchema.validate(formData, { abortEarly: false });
                const treeList = convertTreeToList<NavigationTriggerRule>(formData?.rulesTree);

                const defaultFrequencyInMinutes = 15;
                const ruleConfigurationUpdate: NavigationTriggerRuleConfigurationUpdate = {
                    frequencyInMinutes:
                        (interval || navigationTriggerRuleConfiguration?.frequencyInMinutes) ??
                        defaultFrequencyInMinutes,
                    id: navigationTriggerRuleConfiguration?.id ?? '',
                    rules: treeList.map(
                        (item) =>
                            ({
                                id: item.id,
                                conditionType: item.conditionType,
                                conditionValue: item.conditionValue,
                                eventType: item.eventType,
                                logicalOperator: item.logicalOperator,
                                parentId: item?.parentId === '' ? null : item?.parentId
                            } as NavigationTriggerRule)
                    )
                };
                const ruleConfiguration = await UpdateRuleConfigurationAsync(ruleConfigurationUpdate);
                return ruleConfiguration.data as NavigationTriggerRuleConfiguration;
            } catch (_error) {
                if (_error instanceof ValidationError) {
                    const errorMessages = {};
                    _error.inner.forEach((error) => (errorMessages[error.path] = error.message));
                    formRef.current.setErrors(errorMessages);

                    const firstErroredFieldName = Object.keys(formRef.current.getErrors())[0];
                    const erroredField = formRef.current.getFieldRef(firstErroredFieldName);
                    scrollTo(erroredField);
                    erroredField?.focus();

                    isDevelopment() && console.log(errorMessages);
                } else if (_error instanceof EnviouApiResponseError) {
                    error(_error.message);
                } else {
                    error(String(errors.failedChangingTemplates.title), (_error as Error)?.message);
                }
                captureException(_error);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [rulesTree]
    );

    return (
        <CreateStepTemplate
            formRef={formRef}
            subToolId={subToolId}
            subToolKeyIdentifier='navigationTrigger'
            smsTemplateVariables={smsTemplateVariables}
            emailTemplateVariables={emailTemplateVariables}
            whatsAppTemplateVariables={whatsappTemplateVariables}
            pushTemplateVariables={pushTemplateVariables}
            messageSequenceStep={messageSequenceStep}
            subToolPath={subToolPath}
            onSubToolSaved={updateNavigationTriggerConfiguration}
            subToolConfiguratorElement={
                <AbandonmentSettings subToolColor={subToolColor} initial={rulesTree} onChange={setRulesTree} />
            }
            isWhatsAppBusiness={isWhatsAppBusiness}
        />
    );
};
